
export function getGlobalBoundingClientRect (element) {
    const body = document.body
    const rootElement = document.documentElement
    const clientTop = rootElement.clientTop || body.clientTop || 0
    const clientLeft = rootElement.clientLeft || body.clientLeft || 0
    const scrollTop = window.pageYOffset || rootElement.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || rootElement.scrollLeft || body.scrollLeft
    const clientRect = element.getBoundingClientRect()
    return new DOMRect(
        Math.round(clientRect.left + scrollLeft - clientLeft),
        Math.round(clientRect.top + scrollTop - clientTop),
        clientRect.width,
        clientRect.height
    )
}