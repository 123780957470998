import {m, Button} from '../../n2/components/base.js'
import {EmbeddedForm} from '../../n2/components/form.js'
import FormModel from '../../n2/data/FormModel.js'
import App from '../state/App.js'

import {
    BackButton
} from '../../shared/components/MainComponents.js'
import Entity from '../../n2/data/Entity.js'

export default class ProbandForm extends EmbeddedForm {
    constructor(vnode) {
        super(vnode)
        App.probands.onceLoaded(() => {
            this.formModel = new FormModel(App.probands, this.getOperation(), this.getEntity())
            this.configureModel(this.formModel)
        })
    }

    getModel() {
        return this.formModel;
    }

    getSaveLabel() {
        return 'Testperson ' + (this.getOperation() === 'create' ? 'anlegen' : 'speichern')
    }

    getSuccessLabel() {
        const name = this.entity.firstName + ' ' + this.entity.lastName;
        return (this.getOperation() === 'create' ?
            'Sie haben erfolgreich einen neuen Eintrag für ' + name + ' hinzugefügt.' :
                'Sie haben erfolgreich die persönlichen Daten von ' + name + ' aktualisiert.'
        )
    }

    getOperation() {
        return this.attrs.operation || 'view'
    }

    getEntityId() {
        return this.attrs.entityId || 0
    }

    getEntity() {
        return App.probands.all.byId[this.getEntityId()] || {}
    }

    onSave(model) {
        this.entity = model.getEntity()
        setTimeout(() => m.route.set('/probands/' + this.entity.id), 2500)
    }

    configureModel(model) {
        //model.setAlwaysTainted(true)
        model.mandatory('firstName,lastName,addressStreet,addressNumber,addressZip,addressCity,addressCountry,birthday,passId')
        if (this.getOperation() === 'create') {
            model.mandatory('priv')
            model.addConstraint('priv', (e) => e.priv || 'Das Anlegen eines Probanden erfordert diese Zustimmung')
        }
        model.addConstraint('birthday', (e) => {
            if (!(e.birthday instanceof Date)) return 'Ungültiges Datumsformat'
            const tooOld = new Date()
            tooOld.setFullYear(tooOld.getFullYear() - 130)
            if (e.birthday < tooOld || e.birthday > new Date()) return 'Ungültiges Geburtsdatum'
            const tooYoung = new Date()
            tooYoung.setFullYear(tooYoung.getFullYear() - 12)
            if (e.birthday > tooYoung) return 'Kinder unter 12 Jahren sind zum Test nicht zugelassen'
            return true
        })
    }

    renderReturnButton() {
        return this.attrs.operation !== 'view'
            ? (m(BackButton, {title: 'Abbrechen', backlink: '/probands'}))
            : false
    }

    renderContent() {
        //this.getOperation() === 'view'
        return this.group([
            this.hgroup([
                this.string('firstName', 'Vorname'),
                this.string('lastName', 'Nachname'),
            ]),
            this.textualDate('birthday', 'Geburtsdatum'),
            this.hgroup([
                this.string('addressStreet', 'Straße'),
                this.string('addressNumber', 'Hausnummer'),
            ]),
            this.string('addressAddition', 'Adresszusatz'),
            this.hgroup([
                this.string('addressZip', 'Postleitzahl'),
                this.string('addressCity', 'Stadt/Ort'),
            ]),
            this.string('addressCountry', 'Land'),
            this.string('passId', 'Ausweisnummer'),
            this.string('phone', 'Telefon'),
            this.getOperation() === 'create' &&
                this.option('priv', 'Hiermit bestätige ich, dass ich entweder der Sorgeberechtigte bzw. ' +
                    'gesetzliche Vormund bzw. rechtliche Betreuer der neu angelegten Testperson oder die Testperson selbst bin.'),
        ])
    }

    renderChildren() {
        return this.entity ? m('p.mt-6.text-brand1.text-center.font-bold', this.getSuccessLabel()) : super.renderChildren()
    }
}
