import {
    Button,
    m
} from '../../n2/components/base.js'

import {MainHeadline} from '../../shared/components/MainComponents.js'

import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

import {
    TrashIcon,
    EyeIcon,
    MailIcon,
} from '../../n2/icons.js'

import Card from '../../shared/components/Card.js'

const iconCards = [
    {
        title: 'Passwort ändern',
        icon: EyeIcon,
        cardlink: '/change-password',
    },
    /*{
        title: 'E-Mail-Adresse ändern',
        icon: MailIcon,
        cardlink: '/change-email',
    },*/
    {
        title: 'Konto löschen',
        icon: TrashIcon,
        cardlink: '/delete-account',
    },
]

export default class UserPage extends AppPage {
    renderIconCards() {
        return m('div.flex.flex-wrap',
            iconCards.map(a =>
                m(Card,
                    {
                        title: a.title,
                        subtitle: a.subtitle,
                        icon: a.icon,
                        cardAction: a.cardlink,
                        size: a.size,
                    }
                ),
            ),
        )
    }

    renderChildren() {
        return [
            m(MainHeadline, {title: 'Mein Konto'}),
            m('hr.border-gray-darkest.my-2'),
            this.renderIconCards(),
        ]
    }
}
