import {
    Button,
    m
} from '../../n2/components/base.js'

import {MainHeadline} from '../../shared/components/MainComponents.js'

import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

export default class ImprintPage extends AppPage {
    renderChildren() {
        return [
            m(MainHeadline, {title: 'Impressum'}),
            m('hr.border-gray-darkest.my-2'),
        ]
    }
}
