import { m, Button, Control } from '../../n2/components/base.js'
import App from '../state/App.js'
import AppMenu from './AppMenu.js'
import AppFooter from './AppFooter.js'
import {Modal} from '../../n2/components/modal.js'


export default class AppFrame extends Control {
    constructor(vnode) {
        super(vnode, '.flex.flex-col.w-full.min-h-screen.overflow-hidden')
    }

    oninit() {
        if (!App.user.isLoggedIn()) {
            m.route.set('/login')
        }
    }

    getTitle() {
        return this.attrs.title || ''
    }

    async logout() {
        await App.user.logout()
        m.route.set('/login')
    }

    renderHeader() {
        return m(AppMenu, {
            user: App.user,
            menuItems: [
                {
                    title: 'Testpersonen',
                    href: '/probands'
                },
                {
                    title: 'Termine',
                    href: '/bookings'
                },
                {
                    title: 'Tests',
                    href: '/tests'
                },
                {
                    title: 'Mein Konto',
                    href: '/account'
                },
                {
                    title: 'Abmelden',
                    onClick: () => this.logout()
                },
            ]
        });
    }

    renderFooter() {
        return m(AppFooter, {
            menuItems: [
                {
                    title: 'Datenschutz',
                    href: 'https://nextcontrol.de/datenschutz',
                    target: '_blank'
                },
                {
                    title: 'Impressum',
                    href: 'https://nextcontrol.de/impressum',
                    target: '_blank'
                },
            ]
        });
    }

    renderChildren() {
        return [
            this.renderHeader(),
            m('.container.mx-auto.px-4.py-10.flex-initial.flex-grow',
                m('.w-full.lg:w-3/4.xl:w-3/5.mx-auto', ...this.vnode.children)
            ),
            this.renderFooter(),
            Modal.view()
        ]
    }
}
