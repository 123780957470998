import {Theme, Button, Icon, m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'
import ProbandForm from '../components/ProbandForm.js'
import {
    CalendarIcon,
    UsersIcon,
    AvatarIcon,
    ArchiveIcon
} from '../../n2/icons.js'

import {
    MainHeadline,
    BackButton
} from '../../shared/components/MainComponents.js'
import Card from '../../shared/components/Card.js'

const iconCards = [
    {
        title: 'Testpersonen',
        subtitle: 'Bearbeiten, Test einsehen, Termin buchen',
        icon: UsersIcon,
        cardlink: '/probands',
    },
    {
        title: 'Terminübersicht',
        subtitle: 'QR-Code für Termin, Termin buchen',
        icon: CalendarIcon,
        cardlink: '/bookings',
    },
    {
        title: 'Testübersicht',
        subtitle: 'Test-Download',
        icon: ArchiveIcon,
        cardlink: '/tests',
    },
    {
        title: 'Mein Konto',
        subtitle: 'Bearbeiten',
        icon: AvatarIcon,
        cardlink: '/account',
    },
]

export default class StartPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    renderIconCards() {
        return m('div.flex.flex-wrap',
            iconCards.map(a =>
                m(Card,
                    {
                        title: a.title,
                        subtitle: a.subtitle,
                        icon: a.icon,
                        cardAction: a.cardlink,
                        size: a.size,
                    }
                ),
            ),
        )
    }

    renderChildren() {
        return [
            m(MainHeadline, {title: 'Willkommen'}),
            m('hr.border-gray-darkest.my-2'),
            this.renderIconCards(),
        ]
    }
}
