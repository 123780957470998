import {Button, Control, Input, m, TextArea, Theme} from './base.js'
import { Layout } from './layout.js'
import {
    Select,
    MultiSelect,
} from './pick.js'
import {DatePicker, DateRangePicker, TextDatePicker} from './time.js'
import {toDateRangeString, toDateString} from '../dateTime.js'
import Options from '../data/Options.js'
import FormModel from '../data/FormModel.js'
import {Modal} from './modal.js'

const labelClass = '.text-md.text-gray-darkest.font-bold'

async function confirmLeave(model) {
    if(model.isEdited() && await Modal.confirm(
        'Das Formular enthält ungesicherte Änderungen. Sollen diese verworfen werden?',
        { title: 'Änderungen verwerfen?', acceptKind: 'danger' }
    ) !== 'accept') return false
}

async function confirmSave(model) {
    if(await Modal.confirm(
        [
            'Warnungen:',
            m('br'),
            m('ul', model.getGeneralWarnings().map(w => m('li', w)))
        ],
        { title: 'Achtung', acceptKind: 'danger' }
    ) !== 'accept') {
        return false
    } else {
        return await accept(model, true)
    }
}

async function accept(model, ignoreWarnings) {
    const result = await model.save(ignoreWarnings)
    if (result === 'problems') {
        return false
    } else if (result === 'warnings') {
        return await confirmSave(model)
    } else {
        return true
    }
}

export class Form extends Control {
    constructor(vnode) {
        let classString = '';
        if (Theme.form !== undefined && Theme.form['wrapper'] !== undefined) {
            for (const prop in Theme.form['wrapper']) {
                classString += Theme.form['wrapper'][prop] + ' ';
            }
        }
        super(vnode, 'div', { class: classString})
    }

    static async openModal(dataSource, action, entity, configure, render, title, renderInfo) {
        const model = new FormModel(dataSource, action, entity)
        configure(model)
        const modal = new Modal({
            title,
            accept: action !== 'view' && {
                kind: 'primary',
                label: action === 'create' ? 'Anlegen' : (action === 'update' ? 'Speichern' : ''),
                disabled: () => !model.isTainted(),
                action: () => {
                    if(model.canSave()) return accept(model, false)
                    model.setAllEdited()
                    const firstError = document.getElementsByClassName('error')[0]
                    if (firstError) {
                        firstError.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'})
                    }
                    return false
                }
            },
            cancel: {
                kind: action === 'view' ? 'primary' : 'secondary',
                label: (action === 'view' ? 'Schließen' : 'Verwerfen')
            },
            close: {
                action: () => confirmLeave(model)
            },
            renderContent: () => m(Form, {
                model,
                renderContent: (form) => render(form)
            }),
            renderInfo: renderInfo && (() => renderInfo(model))
        })
        return { button: await modal.show(), entity: model.getEntity() }
    }

    getModel() {
        return this.attrs.model
    }

    getType() {
        return this.attrs.type || 'default'
    }

    surround(vnode, property, label) {
        const type = this.getType()
        if (type !== 'simple' && this.getModel().isMandatory(property)) {
            vnode = m('.relative.w-full', [
                m('div', {class: this.getStyleClasses('surround')}),
                m('.relative', m('.relative', vnode))
            ])
        }
        const showProblems = this.getModel().shouldShowPropertyProblems(property)
        const problems = this.getModel().getPropertyProblems(property)
        return m(Layout.VBox, {class: 'mb-4'}, [
            type !== 'simple' && label && m('div', {class: this.getStyleClasses('label')}, label),
            vnode,
            showProblems &&
            problems.map(p => m('.error', {class: this.getStyleClasses('propertyErrorText')}, p))
        ])
    }

    readOnly(label, element) {
        return this.hasChildren(element) && m(Layout.VBox, {class: 'mb-4'}, [
            m('div', {style: {minWidth: '200px'}}, [
                m('div', {class: this.getStyleClasses('label')}, label),
                m('div', element),
            ])
        ])
    }

    getStyleClasses(type) {
        let classString = '';
        if (Theme.form !== undefined && Theme.form[type] !== undefined) {
            for (const prop in Theme.form[type]) {
                classString += Theme.form[type][prop] + ' ';
            }
        }
        return classString;
    }

    string(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value) :
            this.surround(m(
                Input,
                Object.assign({
                    class: `w-full ` + this.getStyleClasses('input'),
                    type: 'text',
                    placeholder: label,
                    value,
                    onChange: (v) => this.getModel().setValue(property, v)
                }, attrs || {})), property, (this.getModel().isMandatory(property) || (value && value.length)) && label
            )
    }

    text(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value) :
            this.surround(m(
                TextArea,
                Object.assign({
                    class: this.getStyleClasses('input'),
                    cols: 50,
                    rows: this.getModel().isEmpty(property) ? 1 : 2,
                    placeholder: label,
                    value,
                    onChange: (v) => this.getModel().setValue(property, v)
                }, attrs || {})), property, (this.getModel().isMandatory(property) || (value && value.length)) && label
            )
    }

    number(property, label, attrs) {
        const value = this.getModel().getValue(property)
        const isNumeric = !isNaN(parseFloat(value)) && isFinite(value)
        const asString = isNumeric ? (value * 1).toLocaleString() : value
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, asString) :
            this.surround(m(
                Input,
                Object.assign({
                    class: this.getStyleClasses('input'),
                    type: 'number',
                    placeholder: label,
                    value,
                    onChange: (v) => this.getModel().setValue(property, v)
                }, attrs || {})), property, (this.getModel().isMandatory(property) || value) && label
            )
    }

    option(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value ? 'ja' : 'nein') :
            this.surround(m('.space-x-1', [
                m(Input,
                    Object.assign({
                        id: 'check-' + property,
                        type: 'checkbox',
                        value,
                        onChange: (v) => this.getModel().setValue(property, Boolean(v))
                    }, attrs || {})
                ),
                m('label', { for: 'check-' + property }, label)
            ]), property)
    }

    options(property, label, attrs) {
        const options = this.getModel().getOptions(property)
        const value = this.getModel().getArray(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value ? Options.getSelectedLabel(options, value) : '<keine Auswahl>') :
            this.surround(m(MultiSelect, Object.assign({
                placeholder: label,
                options,
                value,
                onChange: value => this.getModel().setArray(property, value, true)
            }, attrs || {})), property, (this.getModel().isMandatory(property) || (value && value.length > 0)) && label
        )
    }

    form(property, label, render, attrs) {
        const model = this.getModel().getSubModel(property)
        return this.group([
            label && this.label(label),
            m(Form, { model, configureModel: this.getModel().getSubModelConfigurator(property), renderContent: render })
        ])
    }

    formList(property, label, render, attrs) {
        const editing = !this.getModel().isViewing()
        const buttonCls = '.my-1.rounded-full.text-center.w-4.h-4.bg-brand1-lightest.hover:bg-brand1.text-white.text-xs.align-middle.font-bold.select-none'
        const models = this.getModel().getSubModels(property)
        return m('.flex.flex-col', [
            (models.length > 0 || !this.getModel().isViewing()) && this.label(label),
            m('.flex.flex-col.m-0.p-0', ...models.map((model, index) => m(Layout.HBox, { key: model.key }, [
                editing && m(buttonCls, { onclick: () => this.getModel().removeSubModel(property, index) }, '-'),
                m(Form, { model, configureModel: this.getModel().getSubModelConfigurator(property), renderContent: render })
            ]))),
            editing && m(buttonCls, { onclick: () => this.getModel().appendNewSubModel(property) }, '+'),
        ])
    }

    date(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value && toDateString(value)) :
            this.surround(
                attrs && attrs.native ?
                    m(
                        Input,
                        Object.assign({
                            value,
                            type: 'date',
                            class: this.getStyleClasses('input'),
                            onChange: value => this.getModel().setValue(property, value)
                        }, attrs || {})
                    ) :
                    m(
                        DatePicker,
                        Object.assign({
                            placeholder: label,
                            value,
                            onChange: value => this.getModel().setValue(property, value)
                        }, attrs || {})
                    ),
                property,
                (this.getModel().isMandatory(property) || value) && label
            )
    }

    textualDate(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value && toDateString(value)) :
            this.surround(
                    m(
                        TextDatePicker,
                        Object.assign({
                            value,
                            class: this.getStyleClasses('input'),
                            onChange: value => this.getModel().setValue(property, value)
                        }, attrs || {})
                    ),
                property,
                (this.getModel().isMandatory(property) || value) && label
            )
    }

    dateRange(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value && toDateRangeString(value)) :
            this.surround(m(DateRangePicker, Object.assign({
                placeholder: label,
                class: this.getStyleClasses('input'),
                allowPartial: this.getModel().allowsPartial(property),
                value,
                onChange: value => this.getModel().setValue(property, value)
            }, attrs || {})), property, (this.getModel().isMandatory(property) || value) && label
        )
    }

    color(property, label, attrs) {
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, value && m(Input, {type: 'color', disabled: true, value})) :
            this.surround(m(Input, Object.assign({
                type: 'color',
                placeholder: label,
                value,
                onChange: value => this.getModel().setValue(property, value)
            }, attrs || {})), property, (this.getModel().isMandatory(property) || value) && label
        )
    }

    selectable(property, label, attrs) {
        const options = this.getModel().getOptions(property)
        const value = this.getModel().getValue(property)
        return this.getModel().isReadOnly(property) ?
            this.readOnly(label, Options.getSelectedLabel(options, value)) :
            this.surround(m(Select, Object.assign({
                placeholder: label,
                class: this.getStyleClasses('input'),
                options,
                value,
                onChange: value => this.getModel().setValue(property, value)
            }, attrs || {})), property, (this.getModel().isMandatory(property) || value) && label)
    }

    hasChildren(children) {
        return Boolean(
            Array.isArray(children) ?
                children.reduce((hasChildren, child) => hasChildren || (child && !child.isLabel), false) :
                children
        )
    }

    hgroup(children, attrs) {
        return this.hasChildren(children) && m(Layout.PaddedHBox2, attrs || {}, children)
    }

    group(children, attrs) {
        return this.hasChildren(children) && m(Layout.PaddedVBox, attrs || {}, children)
    }

    highlighted(children, attrs) {
        return this.hasChildren(children) &&
            m('div', {class: this.getStyleClasses('highlighted')}, m(Layout.PaddedVBox, attrs || {}, children))
    }

    label(label) {
        const l = m('div', { class: this.getStyleClasses('label') }, label)
        l.isLabel = true
        return l
    }

    button(label, click, attrs) {
        return null
    }

    renderContent() {
        return this.attrs.renderContent ? this.attrs.renderContent(this, this.getModel()) : null
    }

    renderChildren() {
        return this.getModel() ? [
            m('div',{class: this.getStyleClasses('errorTextWrapper')}, this.renderContent()),
            this.getModel().shouldShowGeneralProblems() ?
                m('p', {class: this.getStyleClasses('errorText')}, this.getModel().getGeneralProblems()) : null
        ] : []
    }
}

export class EmbeddedForm extends Form {
    onSave(model) {
        return this.attrs.onSave && this.attrs.onSave(model)
    }

    getSaveLabel() {
        const model = this.getModel()
        if (this.attrs.saveLabel) return this.attrs.saveLabel
        const operation = model.getOperation() || 'view'
        return operation === 'create' ? 'Anlegen' : (operation === 'update' ? 'Speichern' : '')
    }

    async save() {
        const model = this.getModel()
        this.errorMessage = undefined
        try {
            const result = await accept(model, false)
            if (result) this.onSave(model)
        } catch (ex) {
            if (ex.message) this.errorMessage = ex.message
        }
    }

    renderSaveButton() {
        const model = this.getModel()
        return model && model.getOperation() !== 'view' && m(Button, {
            kind: Theme.form.saveButton.kind,
            disabled: !model.isTainted(),
            onclick: () => {
                if (model.canSave()) {
                    this.save()
                } else {
                    model.setAllEdited()
                    const firstError = document.getElementsByClassName('error')[0]
                    if (firstError) {
                        firstError.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'})
                    }
                }
            }
        }, this.getSaveLabel())
    }

    renderReturnButton() {
        return false
    }

    renderChildren() {
        const returnButton = this.renderReturnButton()
        return [
            this.errorMessage ? m('.text-center', m('p.font-bold.text-danger', this.errorMessage)) : false,
            ...super.renderChildren(),
            m('.flex.mt-4' + (returnButton ? '.justify-between' : '.justify-center'), [
                returnButton,
                this.renderSaveButton(),
            ])
        ]
    }
}
