import {
    Theme,
    m,
    Button, Icon, IconButton,
} from './base.js';
import { Layout } from './layout.js';
import { DataTable } from './table.js'
import { Modal } from './modal.js'
import { Form } from './form.js'

import {
    EyeIcon,
    PencilIcon,
    TrashIcon
} from '../icons.js'
import Entity from '../data/Entity.js'

export function deleteEntity(dataSource, entityId, entityName, confirmation) {
    async function reconfirmDelete(entity) {
        if(await Modal.confirm(
            [
                'Warnungen:',
                m('br'),
                m('ul', Entity.getGeneralWarnings(entity).map(w => m('li', w)))
            ],
            { title: 'Achtung', acceptKind: 'danger' }
        ) !== 'accept') {
            return false
        } else {
            return await accept(entity.id, true)
        }
    }
    async function accept(id, ignoreWarnings) {
        try {
            return await dataSource.delete(id, ignoreWarnings)
        } catch ({code, message, entity}) {
            if (code === 403 && entity) {
                if (Entity.hasGeneralProblems(entity)) {
                    Modal.alert([
                        'Probleme:',
                        m('br'),
                        m('ul', Entity.getGeneralProblems(entity).map(w => m('li', w)))
                    ])
                } else if (Entity.hasGeneralWarnings()) {
                    return await reconfirmDelete(entity)
                } else {
                    Modal.alert(entityName + ' konnte nicht gelöscht werden: ' + message)
                }
            } else {
                Modal.alert('Problem bei der Übertragung (Fehler ' + code + '): ' + message)
            }
        }
    }
    return (confirmation || confirmation === undefined) ?
        new Modal({
            title: entityName + ' löschen?',
            accept: {
                kind: 'danger',
                label: 'Löschen',
                action: () => accept(entityId)
            },
            cancel: {
                label: 'Abbrechen'
            },
            renderContent: () => m('p', 'Soll ' + entityName + ' wirklich gelöscht werden?')
        }).show() :
        accept(entityId)
}


export class CrudTable extends DataTable {
    constructor(vnode) {
        super(vnode)
        this.actionColumn = {
            title: 'Aktionen',
            renderCell: (entity, column, index, columnIndex) => this.renderActions(entity, column, index, columnIndex),
            headerAttributes: {
                style: {
                    width: '1px'
                }
            }
        }
    }

    getSingularName() {
        return this.attrs.singularName || 'Eintrag'
    }

    getPluralName() {
        return this.attrs.pluralName || 'Einträge'
    }

    getAdditionalActions() {
        return this.attrs.additionalActions || []
    }

    getAdditionalToolbarActions() {
        return this.attrs.additionalToolbarActions || []
    }

    getEntityName(entity) {
        return this.attrs.entityName ? this.attrs.entityName(entity) : (entity.name || this.getSingularName())
    }

    canCreate() {
        return this.attrs.canCreate || this.attrs.canCreate === undefined
    }

    canUpdate() {
        return this.attrs.canUpdate || this.attrs.canUpdate === undefined
    }

    canDelete() {
        return this.attrs.canDelete || this.attrs.canDelete === undefined
    }

    getColumns() {
        return [
            ...super.getColumns(),
            this.actionColumn
        ]
    }

    configureModel(model) {
        if (this.attrs.configureModel) this.attrs.configureModel(model)
    }

    renderForm(form) {
        if (this.attrs.renderForm) return this.attrs.renderForm(form)
    }

    renderInfo(model) {
        if (this.attrs.renderInfo) return this.attrs.renderInfo(model)
    }

    openEditor(action, entity) {
        return Form.openModal(
            this.getModel().getDataSource(),
            action,
            entity,
            (model) => this.configureModel(model),
            (form) => this.renderForm(form),
            (action === 'create' ? this.getSingularName() : this.getEntityName(entity)) +
                (action === 'create' ? ' anlegen' : (action === 'update' ? ' bearbeiten' : '')),
            (model) => this.renderInfo(model)
        )
    }

    confirmDelete(entity) {
        return deleteEntity(this.getModel().getDataSource(), entity.id, this.getEntityName(entity), true)
    }

    renderActions(entity, column, index, columnIndex) {
        return m(Layout.PaddedHBox, { justify: 'end', grow: true, class: 'w-24' }, [
            ...this.getAdditionalActions().map(a => a(entity, column, index, columnIndex)),
            m(IconButton, {
                title: 'Öffnen...',
                iconFace: EyeIcon,
                onclick: () => this.openEditor('view', entity)
            }),
            this.canUpdate() && m(IconButton, {
                title: 'Bearbeiten...',
                iconFace: PencilIcon,
                onclick: () => this.openEditor('update', entity)
            }),
            this.canDelete() && m(IconButton, {
                title: 'Löschen...',
                iconFace: TrashIcon,
                onclick: () => this.confirmDelete(entity)
            })
        ])
    }

    onCellClick(entity, column, index, columnIndex) {
        if (column !== this.actionColumn) this.openEditor('view', entity)
    }

    renderCreateButton() {
        return this.canCreate() && m(
            Button,
            {
                onclick: () => this.openEditor('create', {})
            },
            this.getSingularName() + ' hinzufügen'
        )
    }

    renderTopRight() {
        return [
            ...this.getAdditionalToolbarActions(),
            super.renderTopRight(),
            this.renderCreateButton()
        ]
    }
}
