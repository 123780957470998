import {
    Input,
    m,
    ValueControl,
} from './base.js';
import {Picker, Select} from './pick.js';
import {
    CalendarIcon,
} from '../icons.js';

import {
    getLongMonthName,
    getShortWeekDayNames,
    getDaysInMonth,
    getFirstDayOfWeek,
    toDate,
    minDate,
    maxDate,
    dateEqual,
    toDateString,
    toDateRangeString
} from '../dateTime.js';

const dayCellSize = (100/7) + '%'

const monthOptions = [...Array(12).keys()]
    .map((v, m) => ({ id: m, name: getLongMonthName(m) }))

const yearsIntoPast = 200
const yearsIntoFuture = 10
const longAgo = (new Date()).getFullYear() - yearsIntoPast
const yearOptions = [...Array(yearsIntoPast + yearsIntoFuture).keys()]
    .map((v, i) => ({ id: longAgo + i, name: String(longAgo + i) })).reverse()

export class MonthPicker extends Select {
    constructor(vnode) {
        super(vnode)
        if (!this.value) this.value = (new Date()).getMonth()
    }

    getOptions() {
        return monthOptions
    }

    getOrder() {
        return false
    }

    getPlaceholder() {
        return this.attrs.placeholder || 'Monat'
    }
}

export class YearPicker extends Select {
    constructor(vnode) {
        super(vnode)
        if (!this.value) this.value = (new Date()).getFullYear()
    }

    getOptions() {
        return yearOptions
    }

    getOrder() {
        return false
    }

    getPlaceholder() {
        return this.attrs.placeholder || 'Jahr'
    }
}

export class DayPicker {

}

export class MonthSheet extends ValueControl {
    constructor(vnode) {
        super(vnode, '.px-3.py-2')
    }

    onVNodeChanged(vnode, old) {
        super.onVNodeChanged(vnode, old)
        const min = this.getMin()
        const max = this.getMax()
        const value = this.getValue()
        if (value) {
            if (min) this.changeValue(maxDate(min, value))
            if (max) this.changeValue(minDate(max, value))
        }
        let currentDate = (this.year === undefined && this.month === undefined) ?
            (value || new Date()) :
            new Date(this.year, this.month)
        if (min) currentDate = maxDate(min, currentDate)
        if (max) currentDate = minDate(max, currentDate)
        this.year = currentDate.getFullYear()
        this.month = currentDate.getMonth()
    }

    getValue() {
        return this.attrs.value
    }

    getMin() {
        return toDate(this.attrs.min)
    }

    getMax() {
        return toDate(this.attrs.max)
    }

    getFrom() {
        return this.attrs.from
    }

    getTill() {
        return this.attrs.till
    }

    getNullable() {
        return this.attrs.nullable === undefined || Boolean(this.attrs.nullable)
    }

    addMonth(delta) {
        let newDate = new Date(this.year, this.month)
        newDate = new Date(newDate.setMonth(newDate.getMonth() + delta))
        this.year = newDate.getFullYear()
        this.month = newDate.getMonth()
    }

    getAttributes() {
        return Object.assign(super.getAttributes(), {
            style: {
                width: '270px'
            }
        })
    }

    renderChildren() {
        const value = this.getValue()
        const min = this.getMin()
        const max = this.getMax()
        const from = this.getFrom()
        const till = this.getTill()
        const daysInMonth = getDaysInMonth(this.year, this.month)
        const cellStyle = { width: dayCellSize, height: dayCellSize }

        const shift = getFirstDayOfWeek()
        const startingDayOfWeek = (new Date(this.year, this.month).getDay() + 7 - shift) % 7
        const shortWeekDayNames = getShortWeekDayNames()
        for(let i = 0; i < shift; i++) {
            shortWeekDayNames.push(shortWeekDayNames.shift())
        }

        return [
            m('.border.border-black.py-2.rounded.bg-white', [
                m('.flex.justify-between.items-center.mb-2', [
                    m('.ml-2', [
                        m('span.text-lg.font-bold', getLongMonthName(this.month)),
                        m('span.ml-1.text-lg.text-brand1.font-normal', this.year)
                    ]),
                    m('div', [
                        [m.trust('&times;'), this.getValue() && this.getNullable(), () => this.changeValue(null)],
                        [m.trust('&#x1438;'), !min || min < new Date(this.year, this.month, 1), () => this.addMonth(-1)],
                        [m.trust('&#x1433;'), !max || max > new Date(this.year, this.month + 1, 0), () => this.addMonth(1)]
                    ].map(([symbol, active, action]) =>
                        m('.inline-flex.p-1.px-2', {
                                class: active ? 'hover:text-brand1 cursor-pointer' : 'hover:text-gray',
                                onclick: active && action
                            },
                            symbol
                        )
                    ))
                ]),
                m('.flex.flex-wrap.mb-3',
                    shortWeekDayNames.map(name =>
                        m('div', { style: cellStyle },
                            m('.text-gray-dark.text-center.text-xs.font-bold', name)
                        )
                    )
                ),
                m('.flex.flex-wrap',
                    [...Array(startingDayOfWeek).keys()].map(() =>
                        m('span.inline-block.mb-1', { style: cellStyle })
                    ).concat([...Array(daysInMonth).keys()].map((d) => {
                        const date = d + 1
                        const dateDate = new Date(this.year, this.month, date)
                        const selectable = (!min || min <= dateDate) && (!max || max >= dateDate)
                        const isToday = dateEqual(new Date(), dateDate)
                        const isSelected =
                            (!from && !till) &&
                            (value && dateEqual(dateDate, value))
                        const highlight =
                            isSelected ||
                            (value && from && dateDate <= value && dateDate >= from) ||
                            (value && till && dateDate >= value && dateDate <= till)
                        const isStart = isSelected || (from && dateEqual(dateDate, from))
                        const isEnd = isSelected || (till && dateEqual(dateDate, till))
                        return m('span.inline-block.mb-1',
                            {
                                style: cellStyle,
                                class:
                                    'border-2 ' +
                                    (isStart ? 'rounded-l-lg ' : '') +
                                    (isEnd ? 'rounded-r-lg ' : '') +
                                    (highlight ?
                                        'border-transparent bg-brand1 hover:bg-brand1-light text-white font-bold' :
                                        (selectable ? 'border-transparent hover:bg-brand1-lightest' : 'border-transparent text-gray')
                                    ) +
                                    (isToday ? ' border-brand1-light' : '')
                            },
                            m('.text-center.text-sm', {
                                class: selectable && 'cursor-pointer',
                                onclick: selectable && !this.readOnly && (() => this.changeValue(dateDate))
                            }, date)
                        )
                    }))
                )
            ])
        ]
    }
}

export class DatePicker extends Picker {
    constructor(vnode) {
        super(vnode)
    }

    getValue() {
        return this.attrs.value
    }

    getMin() {
        return toDate(this.attrs.min)
    }

    getMax() {
        return toDate(this.attrs.max)
    }

    getPanelSize() {
        return this.attrs.panelSize || 'small'
    }

    getPlaceholder() {
        return this.placeholder || 'Datum'
    }

    getIconFace() {
        return CalendarIcon
    }

    renderSelection() {
        return toDateString(this.getValue()) || '?'
    }

    renderPanelContent(select, close) {
        return m(MonthSheet, {
            min: this.getMin(),
            max: this.getMax(),
            value: this.getValue(),
            onChange: (value) => {
                select(value)
            }
        })
    }
}

export class TextDatePicker extends Input {
    constructor(vnode) {
        super(vnode)
        const date = this.attrs.value
        this.shadowValue = date instanceof Date ? toDateString(date) : ''
    }

    getType() {
        return 'text'
    }

    getPlaceholder() {
        return this.placeholder || this.getFormat()
    }

    getFormat() {
        return this.attrs.format || 'tt.mm.jjjj'
    }

    valuesAreEqual(a, b) {
        return dateEqual(a, b)
    }

    checkDate(value) {
        this.shadowValue = value
        const format = this.getFormat()
        let parts = value.match(/(\d+)/g), i = 0, format_part = {}
        format.replace(/(tt|mm|jjjj)/g, (part) => { format_part[part] = i++ })
        const year = parts[format_part['jjjj']]
        if (year < 1000) {
            this.changeValue(false)
        } else {
            const newDate = new Date(year, parts[format_part['mm']] - 1, parts[format_part['tt']])
            this.changeValue((newDate instanceof Date && !isNaN(newDate)) ? newDate : false)
        }
    }

    getAttributes() {
        return Object.assign(super.getAttributes(), {
            oninput: (ev) => this.checkDate(ev.target.value),
            onchange: (ev) => this.checkDate(ev.target.value),
            value: this.shadowValue
        })
    }
}

export class DateRangeSheet extends ValueControl {
    constructor(vnode) {
        super(vnode, '.flex.flex-row')
    }

    getMin() {
        return toDate(this.attrs.min)
    }

    getMax() {
        return toDate(this.attrs.max)
    }

    allowPartial() {
        return Boolean(this.attrs.allowPartial)
    }

    changeDate(rangeComponent, value) {
        const allowPartial = this.allowPartial()
        const range = this.getValue() || []
        range[rangeComponent] = value
        let [from, till] = range
        if (from && till && from > till) {
            if (rangeComponent === 0) {
                till = from
            } else {
                from = till
            }
        }
        if (!allowPartial && !from) from = till
        if (!allowPartial && !till) till = from
        this.changeValue((from || till) ? [from, till] : null)
    }

    renderChildren() {
        const min = this.getMin()
        const max = this.getMax()
        const value = this.getValue()
        return [
            m(MonthSheet, {
                min,
                max,
                from: value && value[0],
                till: value && value[1],
                value: value && value[0],
                onChange: (v) => (!this.allowPartial() && v === null) ?
                    this.changeValue(null) :
                    this.changeDate(0, v)
            }),
            m(MonthSheet, {
                min,
                max,
                from: value && value[0],
                till: value && value[1],
                value: value && value[1],
                onChange: (v) => this.changeDate(1, v)
            })
        ]
    }
}

export class DateRangePicker extends Picker {
    constructor(vnode) {
        super(vnode)
    }

    getMin() {
        return toDate(this.attrs.min)
    }

    getMax() {
        return toDate(this.attrs.max)
    }

    getPanelSize() {
        return this.attrs.panelSize || 'small'
    }

    getPlaceholder() {
        return this.attrs.placeholder || 'Zeitspanne'
    }

    shouldCloseOnSelect() {
        return this.attrs.closeOnSelect === undefined ? false : this.attrs.closeOnSelect
    }

    getIconFace() {
        return CalendarIcon
    }

    renderSelection() {
        return toDateRangeString(this.getValue())
    }

    renderPanelContent(select, close) {
        return m(DateRangeSheet, {
            min: this.getMin(),
            max: this.getMax(),
            value: this.getValue(),
            onChange: (v) => select(v)
        })
    }
}
