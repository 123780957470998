import { m, Button, Control } from '../../n2/components/base.js'
import App from '../state/App.js'

export default class AppFrame extends Control {
    constructor(vnode) {
        super(vnode, '.w-full.h-screen.min-h-screen.overflow-hidden.flex')
    }

    oninit() {
        if (App.user.isLoggedIn()) {
            m.route.set('/home')
        }
    }

    getTitle() {
        return this.attrs.title || ''
    }

    renderLogo() {
        return m('img.mt-4.mb-4.mx-auto', {
            src: '/assets/logo-left.svg',
            style: 'width: 200px;'
        })
    }

    renderHeader() {
        return m('.w-full.text-center.font-bold.text-brand1', [
            this.getTitle()
        ])
    }

    renderPriv() {
        const menuItems = [
                {
                    title: 'Datenschutz',
                    href: 'https://nextcontrol.de/datenschutz',
                    target: '_blank'
                },
                {
                    title: 'Impressum',
                    href: 'https://nextcontrol.de/impressum',
                    target: '_blank'
                },
            ]

        return m('div.flex.justify-center',
                menuItems.map(mi => m('a',
                    {
                        class: 'text-sm px-1',
                        href: mi.href,
                        target: mi.target
                    },
                    mi.title
                )),
            )
    }

    renderChildren() {
        return [m('.container.p-4.mx-auto.flex.flex-col.items-center.justify-center', [
            m('.div.border.border-gray-lightest.bg-white.w-full.sm:w-3/4.lg:w-2/4.xl:w-2/5', [
                m('.self-center.flex.flex-col.shadow-lg.py-9.px-4.sm:px-9', [
                        this.renderLogo(),
                        this.vnode.children,
                        m('p.text-center.mt-1.text-sm', m.trust('powered by terminblock.de / Ein Service von <a href="https://www.nextcontrol.de" title="NEXTcontrol GmbH" target="_blank">nextcontrol</a>')),
                        this.renderPriv(),
                    ]),
                ]),
            ]),
        ];
    }
}
