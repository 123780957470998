import m from '../vendor/mithril.js'
import pages from './pages/index.js'
import AppFrame from './components/AppFrame.js'
import LoginFrame from './components/LoginFrame.js'
import App from './state/App.js'
import {Theme} from '../n2/components/base.js'
import SharedTheme from '../theme.js'
import AppTheme from './theme.js'
import {
    LoginPage,
    RegistrationPage,
    ChangePasswordPage,
    DeleteAccountPage
} from '../shared/pages/userPages.js'
import PublicFrame from './components/PublicFrame.js'

async function ensureLoaded(render) {
    await App.user.ensure()
    return { view: render }
}

async function ensureLoggedIn(render) {
    await App.user.ensure()
    if (!App.user.isLoggedIn()) {
        m.route.set('/login')
    } else return { view: render }
}

const loginPage = (component, title, attrs) => ({
    onmatch: () => ensureLoaded(() => m(LoginFrame, { title }, m(component, attrs)))
})

const internalPage = (component, title, attrs) => ({
    onmatch: () => ensureLoggedIn(() => m(AppFrame, { title }, m(component, attrs)))
})

const publicPage = (component, title, attrs) => ({
    view: () => m(PublicFrame, { title }, m(component, attrs))
})

Theme.init(SharedTheme, AppTheme)

m.route.prefix = '#'
m.route(document.body, '/home', {
    '/registration-privacy' : publicPage(pages.RegistrationPrivacyPage, 'Datenschutz'),
    '/privacy' : publicPage(pages.PrivacyPage, 'Datenschutz'),
    '/imprint' : publicPage(pages.ImprintPage, 'Impressum'),
    '/login' : loginPage(LoginPage, 'Anmeldung', {
        user: App.user,
        next: '/home',
        resetPasswordRoute: '/reset-password',
        registerRoute: '/register',
        renderLoginNote: () => {
            return m('ol.mb-2.lg:text-base.text-sm',
                m('li.font-bold', 'Anmeldung zur Durchführung eines PoC-Antigen-Schnelltestes auf das Corona-Virus SARS-CoV-2'),
                m('li', m.trust('1. <a href="/#/login" class="text-brand1" title="Anmelden">Anmelden</a> oder Account <a href="/#/register" class="text-brand1" title="Registrieren">registrieren</a>')),
                m('li', '2. Personenbezogene Daten eingeben'),
                m('li', '3. Termin buchen'),
                m('li', '4. Befund einsehen'),
            )
        }
    }),
    '/register' : loginPage(RegistrationPage, 'Registrierung', {
        user: App.user,
        next: '/login'
    }),
    '/reset-password' : loginPage(ChangePasswordPage, 'Passwort zurücksetzen', {
        user: App.user,
        next: '/login',
        mode: 'code'
    }),
    '/change-password' : internalPage(ChangePasswordPage, 'Passwortänderung', {
        user: App.user,
        next: '/account',
        mode: 'password'
    }),
    /*'/change-email' : internalPage(ChangeEmailPage, 'E-Mail-Änderung', {
        user: App.user,
        next: '/account'
    }),*/
    '/delete-account' : internalPage(DeleteAccountPage, 'Konto löschen', {
        user: App.user,
        next: '/login'
    }),
    '/home' : internalPage(pages.StartPage, 'Terminblock'),
    '/account': internalPage(pages.UserPage, 'Nutzerkonto'),
    '/create-proband' : internalPage(pages.ProbandPage, 'Proband anlegen', { operation: 'create' }),
    '/probands': internalPage(pages.ProbandsPage, 'Probanden'),
    '/probands/booking' : internalPage(pages.ProbandBookingPage, 'Testtermin buchen'),
    '/probands/:proband' : internalPage(pages.ProbandPage, 'Proband', { operation: 'view' }),
    '/probands/:proband/edit': internalPage(pages.ProbandPage, 'Proband bearbeiten', { operation: 'update' }),
    '/probands/:proband/booking': internalPage(pages.ProbandBookingPage, 'Testtermin buchen'),
    '/probands/:proband/self-test' : internalPage(pages.SelfTestPage, 'Selbsttest'),
    '/bookings' : internalPage(pages.BookingsPage, 'Terminreservierungen'),
    '/bookings/:booking' : internalPage(pages.BookingPage, 'QR Code Seite'),
    '/tests' : internalPage(pages.TestsPage, 'Testergebnisse'),
    '/tests/:test' : internalPage(pages.TestPage, 'Testergebnis'),
})
