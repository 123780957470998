
import StartPage from './StartPage.js'
import UserPage from './UserPage.js'
import ProbandsPage from './ProbandsPage.js'
import ProbandPage from './ProbandPage.js'
import ProbandBookingPage from './ProbandBookingPage.js'
import BookingsPage from './BookingsPage.js'
import BookingPage from './BookingPage.js'
import TestsPage from './TestsPage.js'
import TestPage from './TestPage.js'
import SelfTestPage from './SelfTestPage.js'
import PrivacyPage from './PrivacyPage.js'
import RegistrationPrivacyPage from './RegistrationPrivacyPage.js'
import ImprintPage from './ImprintPage.js'

export default {
    StartPage,
    UserPage,
    ProbandsPage,
    ProbandPage,
    ProbandBookingPage,
    BookingsPage,
    BookingPage,
    TestsPage,
    TestPage,
    SelfTestPage,
    PrivacyPage,
    RegistrationPrivacyPage,
    ImprintPage,
}
