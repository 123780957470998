const module = {}

module.exports = {
    form: {
        input: {
            padding: 'py-2',
        },
    }
}

export default module.exports
