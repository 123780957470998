const module = {}

module.exports = {
    colors: {
        transparent: 'transparent',
        current: 'currentColor',
        brand1: {
            darkest: '#DF6501',
            dark: '#FE780B',
            DEFAULT: '#FF8625',
            light: '#ffae6e',
            lightest: '#ffe0c7'
        },
        brand2: '#000',
        black: '#000',
        white: '#fff',
        gray: {
            darkest: '#2b2b2b',
            dark: '#707070',
            DEFAULT: '#9f9f9f',
            light: '#dedede',
            lightest: '#f3f2f2',
        },
        blue: {
            darkest: '#002f5f',
            dark: '#3b73ab',
            DEFAULT: '#007ec5',
            light: '#007ec5',
            lightest: '#dedede',
        },
        pink: {
            dark: '#57004f',
            DEFAULT: '#83427D',
            light: '#C1A1BE'
        },
        danger: {
            DEFAULT: '#f00',
                light: '#f88',
                lightest: '#faa',
        },
        success: {
            DEFAULT: '#28a745',
        }
    },
    fontFamily: {
        sans: ['Helvetica Neue LT', 'sans-serif'],
        serif: ['Merriweather', 'serif'],
        mono: ['ui-monospace', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace'],
    },
    form: {
        wrapper: {
            display: 'flexy',
            flexDirection: 'flex-col',
            flexGrow: 'flex-grow',
            spaceBetween: 'space-y-2',
        },
        label: {
            fontSize: 'text-md',
            color: 'text-gray-darkest',
            fontWeight: 'font-bold',
        },
        input: {
            padding: 'py-0',
        },
        highlighted: {
            borderRadius: 'rounded',
            borderColor: 'border-brand1-lightest',
            border: 'border-2',
            margin: 'm-2',
            padding: 'p-2',
        },
        saveButton: {
            kind: 'primary',
        },
        generalErrorText: {
            backgroundColor: 'bg-gray-lightest',
            borderRadius: 'rounded-sm',
            padding: 'p-3',
            color: 'text-danger',
        },
        generalErrorTextWrapper: {
            padding: 'p-1',
        },
        propertyErrorTextWrapper: {
            padding: 'p-1',
        },
        propertyErrorText: {
            color: 'text-danger',
            fontSize: 'text-xs',
        },
        surround: {
            position: 'absolute',
            positionClass: 'top-0',
            width: 'w-2',
            height: 'h-2',
            backgroundColor: 'bg-brand1',
            marginLeft: '-ml-0.5',
            marginTop: '-mt-0.5',
        }
    },
}

export default module.exports