
export default class Options {
    static getOptionsArray(optionsSource, ordered) {
        let options
        if (!optionsSource) {
            options = []
        } else if (typeof optionsSource === 'function') {
            options = this.getOptionsArray(optionsSource(), false)
        } else if (Array.isArray(optionsSource)) {
            options = [...optionsSource]
        } else if (Array.isArray(optionsSource.array)) {
            options = [...optionsSource.array]
        } else {
            options = Object.entries(optionsSource).map(([k, v]) => ({ id: k, name: v }))
        }
        if (ordered === undefined || ordered) {
            options.sort(typeof ordered === 'function' ? ordered : (a, b) => {
                const la = this.getOptionLabel(a).toLowerCase()
                const lb = this.getOptionLabel(b).toLowerCase()
                return (la > lb) ? 1 : ((la < lb) ? -1 : 0)
            })
        }
        return options
    }

    static findOption(options, value) {
        if (!options) {
            return undefined
        } else if (typeof options === 'function') {
            return this.findOption(options(), value)
        } else if (Array.isArray(options)) {
            return options.find(o => o.id === value)
        } else if (Array.isArray(options.array)) {
            return this.findOption(options.array, value)
        } else if (options.byId) {
            return options.byId[value]
        } else if (options.hasOwnProperty(value)) {
            return { id: value, name: options[value] }
        } else {
            return undefined
        }
    }

    static getOptionLabel(option) {
        return option ? (option.label || option.name) : '?'
    }

    static getSelectedOptions(options, value) {
        const selected = []
        for (let id of value || []) {
            const option = this.findOption(options, id)
            if (option) selected.push(option)
        }
        return selected
    }

    static getSelectedLabel(options, value) {
        if (Array.isArray(value)) {
            const label = this.getSelectedOptions(options, value).map(o => this.getOptionLabel(o)).join(', ')
            return label.length > 0 ? label : undefined
        }
        return this.getOptionLabel(this.findOption(options, value))
    }
}
