import {m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import {
    MainHeadline,
} from '../../shared/components/MainComponents.js'
import Card from '../../shared/components/Card.js'
import cancelEntity from '../../shared/components/CancelEntity.js'
import App from '../../app/state/App.js'
import {toDateString, toTimeString} from '../../n2/dateTime.js'
import {
    CalendarIcon,
    UsersIcon,
    QrIcon
} from '../../n2/icons.js'

export default class BookingsPage extends AppPage {
    constructor(vnode) {
        super(vnode)
        this.date = new Date()
    }

    static async cancelBooking(booking) {
        return cancelEntity(App.bookings, booking.id, "Termin am " + toDateString(booking.startTime), true, !booking.canceled)
    }

    static renderBookings(bookings, date) {
        if (bookings.length > 0) {
            return bookings.map(booking => {
                const startDate = new Date(booking.startTime)
                const endDate = new Date(booking.endTime)
                let buttonTitle = 'Absagen'
                let showButton = true
                let strikedText = false
                if (startDate <= date && endDate >= date && !booking.canceled) {
                    showButton = false
                    buttonTitle = ''
                } else if (endDate < date || booking.canceled) {
                    strikedText = true
                    buttonTitle = 'Löschen'
                }
                const proband = App.probands.all.byId[booking.proband]
                const probandName = proband ? (' für ' + proband.name) : ''
                return m(Card, {
                    title: (booking.startTime && booking.endTime) ?
                        ('Am ' + toDateString(booking.startTime) +
                        ' zwischen ' + toTimeString(booking.startTime) +
                        ' und ' + toTimeString(booking.endTime) + ' Uhr')
                        :
                        ('Am ' + toDateString(booking.when) +
                        ' um ca. ' + toTimeString(booking.when) + ' Uhr'),
                    subtitle: 'Gebucht am ' + toDateString(booking.createdAt) + probandName,
                    icon: CalendarIcon,
                    cardAction: '/bookings/' + booking.id,
                    size: 'lg',
                    button: showButton,
                    buttontitle: buttonTitle,
                    buttonAction: () => this.cancelBooking(booking),
                    strikedText: strikedText,
                    full_text: true
                })
            })
        } else {
            return m('p.text-xl.text-gray.text-center', 'Es gibt noch keine gebuchten Termine.')
        }
    }

    renderChildren() {
        return [
            m(MainHeadline, {title: 'Terminübersicht'}),
            //this.renderIconCards(),
            m('.flex.w-full.sm:w-1/2', [
                m(Card,
                    {
                        title: 'Neuen Testtermin buchen',
                        icon: CalendarIcon,
                        cardAction: '/probands/booking',
                        size: 'lg',
                    }
                ),
            ]),
            m('hr.border-gray-darkest.my-2'),
            this.constructor.renderBookings(App.bookings.all.array, this.date),
        ]
    }
}
