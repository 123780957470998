import mithril from '../../vendor/mithril.js';
import {getGlobalBoundingClientRect} from '../dom.js'
import {dateEqual} from '../dateTime.js'

export const m = mithril


export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function assignDeep(target, ...sources) {
    const isObject = (value) => (value && typeof value === 'object' && !Array.isArray(value))
    if (!sources.length) return target
    const source = sources.shift()
    if (isObject(target) && isObject(source)) {
        for (const [key, value] of Object.entries(source)) {
            if (isObject(value)) {
                if (!target[key]) target[key] = {}
                assignDeep(target[key], value)
            } else {
                target[key] = source[key]
            }
        }
    }
    return assignDeep(target, ...sources)
}

export const Theme = {
    init: (...contents) => {
        assignDeep(Theme, ...contents)
    }
}

const sizeToClasses = {
    'tiny': 'text-sm px-1',
    'small': 'px-1',
    'normal': 'py-1 px-2',
    'large': 'w-80 py-2 px-3',
    'free': 'py-2 px-3'
}

export class Control {
    constructor(vnode, classOrComponent) {
        this.classOrComponent = classOrComponent
        this.onVNodeChanged(vnode, {})
    }

    onVNodeChanged(vnode, old) {
        this.vnode = vnode || {}
        this.attrs = vnode.attrs || {}
    }

    getClassOrComponent() {
        return this.classOrComponent || 'div'
    }

    getAttributes() {
        const attrs = {}
        for(const [key, value] of Object.entries(this.attrs)) {
            const attributeName = key.charAt(0).toUpperCase() + key.slice(1)
            if (!this['get' + attributeName] && !this['on' + attributeName]) {
                attrs[key] = value
            }
        }
        return attrs
    }

    renderChildren() {
        return this.vnode.children
    }

    render() {
        const component = this.getClassOrComponent()
        return component && m(component, this.getAttributes(), this.renderChildren())
    }

    onbeforeupdate(vnode, old) {
        return this.onVNodeChanged(vnode, old)
    }

    view(vnode) {
        return this.render()
    }
}

export class ValueControl extends Control {
    constructor(vnode, classOrComponent) {
        super(vnode, classOrComponent)
    }

    onChange(newValue, oldValue) {
        return this.attrs.onChange && this.attrs.onChange(newValue, oldValue)
    }

    onVNodeChanged(vnode, old) {
        super.onVNodeChanged(vnode, old)
        if (this.attrs.hasOwnProperty('value')) {
            this.value = this.attrs.value
        }
    }

    isReadOnly() {
        return Boolean(this.attrs.readOnly)
    }

    getValue() {
        return this.value
    }

    valuesAreEqual(a, b) {
        return a === b
    }

    setValue(value) {
        if (!this.valuesAreEqual(this.value, value)) {
            this.value = value
            return true
        }
        return false
    }

    changeValue(newValue) {
        const oldValue = this.getValue()
        if (this.setValue(newValue)) {
            this.onChange(this.value, oldValue)
        }
    }
}

let popupIdCounter = 0

export class Popup extends Control {
    constructor(vnode) {
        super(vnode, '.relative')
        this.id = popupIdCounter++
        this.listenToScroll = (ev) => {
            if (ev.target.id !== 'popup-panel-' + this.id) {
                this.close()
            }
        }
    }

    onremove() {
        this.close()
    }

    isOpen() {
        return this.panelOpen
    }

    onOpen() {
        this.attrs.onOpen && this.attrs.onOpen(this)
    }

    onClose() {
        this.attrs.onClose && this.attrs.onClose(this)
    }

    setOpen(open) {
        if (open !== this.panelOpen) {
            this.panelOpen = open
            if (open) {
                document.addEventListener('scroll', this.listenToScroll, true)
                this.onOpen()
            } else {
                document.removeEventListener('scroll', this.listenToScroll, true)
                this.onClose()
            }
            m.redraw()
        }
    }

    open() {
        this.setOpen(true)
    }

    close() {
        this.setOpen(false)
    }

    toggle() {
        this.setOpen(!this.isOpen())
    }

    getAttributes() {
        return Object.assign(super.getAttributes(), {
            id: 'popup-box-' + this.id,
            tabIndex: -1,
            onclick: (ev) => this.toggle(),
            onkeyup: (ev) => ev.code === 'Escape' && (this.close())
        })
    }

    getPanelWidth() {
        return this.attrs.panelWidth && (this.attrs.panelWidth + 'px')
    }

    getPanelHeight() {
        return this.attrs.panelHeight && (this.attrs.panelHeight + 'px')
    }

    shouldCloseOnBlur() {
        return this.attrs.closeOnBlur !== false
    }

    renderAnchor() {
        return this.attrs.renderAnchor && this.attrs.renderAnchor(this, this.getAttributes())
    }

    renderContent() {
        return this.attrs.renderContent && this.attrs.renderContent(this)
    }

    onPanelKeyUp(ev) {
        this.attrs.onPanelKeyUp && this.attrs.onPanelKeyUp(this, ev)
    }

    onPanelKeyDown(ev) {
        this.attrs.onPanelKeyDown && this.attrs.onPanelKeyDown(this, ev)
    }

    render() {
        const rect = this.isOpen() ?
            getGlobalBoundingClientRect(document.getElementById('popup-box-' + this.id)) :
            null
        return [
            this.renderAnchor(),
            this.isOpen() && m(
                '.fixed.z-10' +
                '.max-h-96' +
                '.overflow-auto' +
                '.transition.duration-500.ease-in-out' +
                '.bg-white.rounded-sm.shadow.border.border-gray' +
                '.select-none' +
                '.outline-none.focus:border-brand1-light', {
                    id: 'popup-panel-' + this.id,
                    style: {
                        width: this.getPanelWidth(),
                        height: this.getPanelHeight(),
                        top: rect.bottom + 'px',
                        left: rect.left + 'px',
                        maxHeight: Math.max(document.body.clientHeight - rect.bottom - 20, 20) + 'px'
                    },
                    tabIndex: this.isOpen() ? -1 : undefined,
                    onkeydown: (ev) => this.onPanelKeyDown(ev),
                    onkeyup: (ev) => {
                        if (ev.code === 'Escape') this.close()
                        this.onPanelKeyUp(ev)
                    },
                    onfocusout: (ev) => {
                        if (this.shouldCloseOnBlur() && !ev.currentTarget.contains(ev.relatedTarget))
                            setTimeout(() => {
                                this.close()
                                m.redraw()
                            }, 200)
                    },
                    oncreate: (vnode) => vnode.dom.focus()
                },
                this.renderContent()
            )
        ]
    }
}

const placeholders = {
    password: 'Passwort',
    email: 'E-Mail-Adresse'
}

const autocompletes = {
    password: 'current-password',
    email: 'email'
}

const inputClasses = '.rounded-sm.border.border-gray-light.pl-1.focus:outline-none.focus:border-brand1-light'

export class Input extends ValueControl {
    constructor(vnode) {
        super(vnode, 'input' + inputClasses)
    }

    getType() {
        return this.attrs.type || 'text'
    }

    getPlaceholder() {
        return this.attrs.placeholder || placeholders[this.getType()]
    }

    getAutocomplete() {
        return this.attrs.autocomplete || autocompletes[this.getType()]
    }

    valuesAreEqual(a, b) {
        return this.getType() === 'date' ? dateEqual(a, b) : super.valuesAreEqual(a, b);
    }

    changeValue(newValue) {
        super.changeValue(this.getType() === 'date' ? new Date(newValue) : newValue);
    }

    getAttributes() {
        const type = this.getType()
        const value = this.getValue()
        const attrs = {
            type,
            autocomplete: this.getAutocomplete(),
            placeholder: this.getPlaceholder(),
            oninput: (ev) => this.changeValue(type === 'checkbox' ? ev.target.checked : ev.target.value),
            onchange: (ev) => this.changeValue(type === 'checkbox' ? ev.target.checked : ev.target.value)
        }
        if (type === 'checkbox') {
            attrs.oncreate = (vnode) => vnode.dom.checked = value
        } else if (type === 'date') {
            if (value instanceof Date) {
                const year = value.getFullYear()
                const month = value.getMonth() + 1
                const date = value.getDate()
                attrs.value = year + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date
            } else {
                attrs.value = value
            }
        } else {
            attrs.value = value
        }
        return Object.assign(super.getAttributes(), attrs)
    }
}

export class TextArea extends ValueControl {
    constructor(vnode) {
        super(vnode, 'textarea' + inputClasses)
    }

    getPlaceholder() {
        return this.attrs.placeholder
    }

    getCols() {
        return this.attrs.cols || 50
    }

    getRows() {
        return this.attrs.rows || 2
    }

    getAttributes() {
        return Object.assign(super.getAttributes(), {
            placeholder: this.getPlaceholder(),
            cols: this.getCols(),
            rows: this.getRows(),
            value: this.getValue(),
            oninput: (ev) => this.changeValue(ev.target.value)
        })
    }
}

const kindToClasses = {
    'primary': 'bg-brand1 border border-brand1 disabled:bg-brand1-light text-white',
    'secondary': 'bg-white border border-brand1 disabled:text-brand1-light text-brand1',
    'danger': 'bg-danger border border-danger disabled:bg-danger-light text-white',
    'default': 'bg-white border-2 border-brand1 disabled:bg-gray-light text-gray'
}

export class Button extends Control {
    constructor(vnode) {
        super(vnode, 'button.rounded-sm.font-bold.focus:outline-none')
    }

    getKind() {
        return this.attrs.kind || 'primary'
    }

    getSize() {
        return this.attrs.size || 'normal'
    }

    getAttributes() {
        const attrs = super.getAttributes()
        return Object.assign(attrs, {
            class: (
                (attrs.class ? attrs.class + ' ' : '') +
                kindToClasses[this.getKind()] + ' ' +
                sizeToClasses[this.getSize()]
            ).trim()
        })
    }
}

export class Icon {
    constructor(vnode) {
        this.iconFace = vnode.attrs.iconFace
        this.size = vnode.attrs.size
        this.color = vnode.attrs.color
        this.opacity = vnode.attrs.opacity
    }

    getPixelSize() {
        return (this.size || (this.iconFace && this.iconFace.size) || 24) + 'px'
    }

    getOpacity() {
        return this.opacity || 1
    }

    view(vnode) {
        return m('img', Object.assign({
            src: vnode.attrs.iconFace && vnode.attrs.iconFace.getIconSrc(this.color, this.opacity),
            width: this.getPixelSize(),
            height: this.getPixelSize(),
        }, vnode.attrs || {}))
    }
}

export class IconButton {
    view(vnode) {
        const pixelSize = (vnode.attrs.size || (vnode.attrs.iconFace && vnode.attrs.iconFace.size) || 24) + 'px'
        return m('img.cursor-pointer', Object.assign({
            src: vnode.attrs.iconFace && vnode.attrs.iconFace.getIconSrc(
                this.highlight ? (vnode.attrs.danger ? Theme.colors.danger.DEFAULT : Theme.colors.brand1.DEFAULT) :  Theme.colors.gray.DEFAULT, vnode.attrs.opacity || 1
            ),
            width: pixelSize,
            height: pixelSize,
            onmouseenter: () => this.highlight = true,
            onmouseleave: () => this.highlight = false,
        }, vnode.attrs || {}))
    }
}
