import {
    Button,
    m
} from '../../n2/components/base.js'

import {
    MainHeadline,
    BackButton
} from '../../shared/components/MainComponents.js'

import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

import QRCode from '../../vendor/qrcode.min.js'

export default class BookingPage extends AppPage {
    renderBackButton() {
        return m('.flex.justify-start.my-4',
            m(BackButton, {backlink: '/bookings'}),
        )
    }

    getEntityId() {
        return m.route.param('booking') || 0
    }

    getCode() {
        return (this.getEntityId() > 0 && App.bookings.all.byId[this.getEntityId()]) ? App.bookings.all.byId[this.getEntityId()].number : 'unbekannt'
    }

    renderChildren() {
        const code = this.getCode()

        return [
                m(MainHeadline, {title: 'QR Code'}),
                m('div.flex.justify-center.flex-col.items-center',
                    m('div.transform.md:scale-100.scale-50.relative.md:h-auto.h-0',
                        m('canvas', {
                            id: 'qrcode',
                            oncreate: (vnode) => {
                                QRCode.toCanvas(document.getElementById('qrcode'), code, {scale: 16}, (error) => {
                                    if (error) console.error(error)
                                })
                            }
                        }),
                    ),
                    m('div.md:h-0.w-full.block.h-80'),
                    m('p.text-3xl.text-brand1.font-bold.text-center.mb-5', code),
                ),
                this.renderBackButton(),
            ]
    }
}
