import {Theme, Button, Icon, m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'

import {
    MainHeadline,
    BackButton,
    ExpandedCard,
} from '../../shared/components/MainComponents.js'

import {
    toDateString,
    toTimeString
} from '../../n2/dateTime.js';

export default class TestPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }


    getEntityId() {
        return m.route.param('test') || 0
    }

    getEntity() {
        return App.tests.all.byId[this.getEntityId()]
    }

    getCompany() {
        return App.centers.all.byId[this.getEntity().company] || {}
    }


    renderBackButton() {
        return m('.flex.justify-start.my-4',
            m(BackButton, {backlink: '/tests'}),
        )
    }

    renderProband(p) {
        return  m('table.table.table-fixed.w-full.my-2',
                m('tr',
                    m('td.w-1/2',
                         m('h3.font-bold', 'Vorname:'),
                         m('p.text-xs', 'first name'),
                    ),
                    m('td.pl-2', p.probandFirstName),
                ),
                m('tr.w-1/2',
                    m('td',
                         m('h3.font-bold', 'Nachname:'),
                         m('p.text-xs', 'last name'),
                    ),
                    m('td.pl-2', p.probandLastName),
                ),
                m('tr',
                    m('td',
                         m('h3.font-bold', 'Geburtstag:'),
                         m('p.text-xs', 'date of birth'),
                    ),
                    m('td.pl-2', toDateString(p.probandBirthday)),
                ),
                p.probandPhone &&  m('tr',
                    m('td',
                         m('h3.font-bold', 'Telefonnummer:'),
                         m('p.text-xs', 'phone'),
                    ),
                    m('td.pl-2', p.probandPhone),
                ),
                m('tr',
                    m('td',
                         m('h3.font-bold', 'Emailadresse:'),
                         m('p.text-xs', 'email'),
                    ),
                    m('td.pl-2', p.probandEmail),
                ),
                m('tr',
                    m('td',
                         m('h3.font-bold', 'Ausweis-/ Passnummer:'),
                         m('p.text-xs', 'ID-/Pass number'),
                    ),
                    m('td.pl-2', p.probandPassId),
                ),

            )
    }

    renderTest(t) {
        return m('table.table.table-fixed.w-full.my-2',
                m('tr',
                    m('td.w-1/2',
                         m('h3.font-bold', 'wurde am (Datum):'),
                         m('p.text-xs', 'was on (date)'),
                    ),
                    m('td.pl-2', toDateString(t.testTime)),
                ),
                m('tr.w-1/2',
                    m('td',
                         m('h3.font-bold', 'um (Uhrzeit):'),
                         m('p.text-xs', 'at (time)'),
                    ),
                    m('td.pl-2', toTimeString(t.testTime) + ' Uhr'),
                ),
                m('tr',
                    m('td',
                         m('h3.font-bold', 'in der Einrichtung:'),
                         m('p.text-xs', 'in the facility'),
                    ),
                    m('td.pl-2', t.testPlace),
                ),
                m('tr',
                    m('td',
                         m('h3.font-bold', 'von (Tester):'),
                         m('p.text-xs', 'by (tester)'),
                    ),
                    m('td.pl-2', t.userFirstname + ' ' + t.userLastname),
                ),
            )
    }

    renderResult(t) {
        const validUntil = new Date(t.testTime.getTime())
        validUntil.setHours(validUntil.getHours() + 24)
        return [
            m('table.table.table-fixed.w-full.my-2',
                m('tr', [
                    m('td.w-1/2',
                        m('h3.font-bold', 'der folgende Test durchgeführt:'),
                        m('p.text-xs', 'the following test was carried out'),
                    ),
                    m('td.pl-2', m('h2.font-bold.text-xl', t.testToolName)),
                ]),
                m('tr', [
                    m('td.w-1/2',
                        m('h3.font-bold', 'Das Testergebnis lautet:'),
                        m('p.text-xs', 'The test result is'),
                    ),
                    m('td.pl-2', m('h2.font-bold.text-xl', { class: t.result ? 'text-danger' : 'text-success' }, (t.result ? 'positiv' : 'negativ'))),
                ]),
                t.remarks && m('tr', [
                    m('td.w-1/2',
                        m('h3.font-bold', 'Kontrolltest-Linie:'),
                        m('p.text-xs', 'Control test line'),
                    ),
                    m('td.pl-2', m('h2.font-bold.text-xl', t.remarks)),
                ]),
                !t.result && m('tr', [
                    m('td.w-1/2',
                        m('h3.font-bold', 'Diese Bescheinigung ist:'),
                        m('p.text-xs', 'This document is valid until'),
                    ),
                    m('td.pl-2', m('h2.font-bold.text-xl', 'gültig bis ' + toDateString(validUntil) + ' ' + toTimeString(validUntil) + ' Uhr')),
                ]),
            ),
            t.result && [
                m('p.mt-6', 'Ihr Testergebnis ist positiv.'),
                m('p.font-bold', 'Begeben Sie sich unverzüglich in Quarantäne und infomieren Sie Ihren Hausarzt.'),
                m('p', 'Eine automatische Meldung an das Gesundheitsamt erfolgt von der Einrichtung.'),
                m('p.text-xs.mt-3', 'Your test result is positive.'),
                m('p.text-xs.font-bold', 'Immediately go into quarantine and inform your family doctor.'),
                m('p.text-xs', 'An automatic report to the health department will be made by the facility.'),
            ]
        ]
    }

    renderInfo(t) {
        return m(ExpandedCard,
                {
                    title: 'Weitere Informationen',
                },
                [
                    (t.headOfMedical || t.headOfTest) && m('div.bg-gray-lightest.border.border-brand1.p-2.mb-3',
                        m('div', [
                            t.headOfTest && m('div.flex.justify-between.items-center',
                                m('div',
                                    m('h2.font-bold', 'Leiter Testzentrum'),
                                    m('p.text-xs', 'Head of Testcenter'),
                                ),
                                m('div',
                                    m('h2.font-bold.text-xl', t.headOfTest),
                                ),
                            ),
                            t.headOfMedical && m('hr.my-2.border-gray'),
                            t.headOfMedical && m('div.flex.justify-between.items-center',
                                m('div',
                                    m('h2.font-bold', 'Ärztlicher Leiter'),
                                    m('p.text-xs', 'Medical Director'),
                                ),
                                m('div',
                                    m('h2.font-bold.text-xl', t.headOfMedical),
                                ),
                            ),
                        ])
                    ),
                    m('div', [
                        m('h2.font-bold', 'Kontakt'),
                        t.companyName && m('p', t.companyName),
                        t.companyHeadOfTest && m('p', t.companyHeadOfTest),
                        t.companyAddressStreet && m('p', t.companyAddressStreet + ' ' + t.companyAddressNumber),
                        t.companyAddressZip && m('p', t.companyAddressZip + ' ' + t.companyAddressCity),
                        t.companyEmail && m('p', t.companyEmail),
                        t.companyPhone && m('p', 'Telefon: ' + t.companyPhone),
                        t.companyFax && m('p', 'Fax: ' + t.companyFax)
                    ]),
                ]
            )
    }

    renderChildren() {
        const t = this.getEntity()
        if (t !== undefined) {
            return [
                m('.w-full.mx-auto', [
                    m('div',
                        m('div.text-center',
                            m(MainHeadline, {title: 'Bescheinigung über die Durchführung zum Nachweis des Coronavirus SARS-CoV-2 mittels PoC-Antigentests vom ' + toDateString(t.testTime) + '.'}),
                            m('p.small', 'Certificate of performance for the detection of coronavirus SARS-CoV-2 by PoC antigen testing.'),
                        ),
                        m('a.flex.flex-wrap.justify-center.my-5', { href: App.tests.getEntityCommandGetUrl(t.id, 'loadDocument') }, m(Button, 'Download PDF')),
                        m('hr.border-brand1.my-2'),
                        m('h2.font-bold', 'Zertifikat: ' + t.number),
                        m('h2.font-bold', 'Bei folgender Person wurde ein PoC-Antigen-Schnelltest durchgeführt:'),
                        m('p.text-xs', 'The PoC antigen test was carried out on the following person:'),
                        this.renderProband(t),
                        m('h2.font-bold', 'Ausgewiesen durch einen Personalausweis oder ein anderes amtliches Lichtbilddokument:'),
                        m('p.text-xs', 'Identified by an identity card or other official photo document:'),
                        this.renderTest(t),
                        this.renderResult(t),
                        m('p.text-xs.mt-8', 'Stand 05/2021 - Dieses Formular ist elektronisch erstellt und ohne Unterschrift gültig.'),
                        m('p.text-xs', 'Status 05/2021 - This form is created electronically and is valid without signature.'),
                    ),
                    this.renderInfo(t),
                    m('div.my-4', this.renderBackButton()),
                ]
            )]
        }
    }
}
