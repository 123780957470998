import {
    Button,
    Control,
    m
} from '../../n2/components/base.js'

import Theme from '../../theme.js'

export default class Timer extends Control {
    constructor(vnode) {
        super(vnode, 'div.my-2')
    }

    getTitle() {
        return this.attrs.title
    }

    getTime() {
        return this.attrs.time || 900
    }

    renderChildren() {
        const FULL_DASH_ARRAY = 283
        const WARNING_THRESHOLD = 10
        const ALERT_THRESHOLD = 5

        const TIME_LIMIT = this.getTime()
        let timePassed = 0
        let timeLeft = TIME_LIMIT
        let timerInterval = null

        //document.getElementById("timer").innerHTML = //'<div class="base-timer relative" style="width: 250px; height:250px;">' +
          //'<svg style="transform: scaleX(-1);" viewBox="0 0 100 100" >' +
            //'<g style="fill: none; stroke: none;">' +
              //'<circle style="stroke-width: 5px; stroke: grey;" cx="50" cy="50" r="45"></circle>' +
              //'<path id="timer-path" style="stroke-width: 5px; stroke-linecap: round; transform: rotate(90deg); transform-origin: center; transition: 1s linear all; fill-rule: nonzero; stroke: currentColor;" stroke-dasharray="283" class="base-timer__path-remaining"' +
                //'d="' +
                  //'M 50, 50' +
                  //'m -45, 0' +
                  //'a 45,45 0 1,0 90,0' +
                  //'a 45,45 0 1,0 -90,0"></path>' +
            //'</g>' +
          //'</svg>' +
          //'<span id="timer-label" class="absolute flex top-0 items-center justify-center" style="width: 250px; height:250px;font-size: 48px;">' + formatTime(timeLeft) + '</span>' +
        //'</div>'

        startTimer();

        function onTimesUp() {
          clearInterval(timerInterval);
        }

        function startTimer() {
          timerInterval = setInterval(() => {
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;
            document.getElementById("timer-label").innerHTML = formatTime(
              timeLeft
            );
            setCircleDasharray();

            if (timeLeft === 0) {
              onTimesUp();
            }
          }, 1000);
        }

        function formatTime(time) {
          const minutes = Math.floor(time / 60);
          let seconds = time % 60;

          if (seconds < 10) {
            seconds = '0' + seconds
          }

          return minutes + ':' + seconds
        }

        function calculateTimeFraction() {
          const rawTimeFraction = timeLeft / TIME_LIMIT
          return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction)
        }

        function setCircleDasharray() {
          const circleDasharray = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
          ).toFixed(0)} 283`
          document
            .getElementById('timer-path')
            .setAttribute('stroke-dasharray', circleDasharray)
        }
        return [m('div.flex.justify-center.items-center', {id: 'timer'},
                m('div.base-timer.relative', {style: {width: '250px', height:'250px'}},
                    m('svg', {style: {transform: 'scaleX(-1)'}, viewBox: '0 0 100 100'},
                        m('g',  {style: {fill: 'none', stroke: 'none'}},
                            m('cirlce', {style: {strokeWidth: '5px', stroke: 'grey'}, cx: '50', cy: '50', r: '45'}),
                            m('path', {id: 'timer-path', style: {strokeWidth: '5px', strokeLinecap: 'round', transform: 'rotate(90deg)', transformOrigin: 'transition 1s linear all', fillRule: 'nonzero', stroke: 'currentColor'}, strokeDasharray: '283', d: 'M 50, 50' +
                              'm -45, 0' +
                              'a 45,45 0 1,0 90,0' +
                              'a 45,45 0 1,0 -90,0'}
                            ),
                        )
                    ),
                    m('span.absolute.flex.top-0.items-center.justify-center', {id: 'timer-label', style: {width: '250px', height:'250px', fontSize: '48px'}}, formatTime(timeLeft))
                )
            )]
    }
}
