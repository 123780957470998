import ManagedEntity from '../../n2/data/ManagedEntity.js'

export default class UserBase extends ManagedEntity {
    constructor(userDataSource) {
        super(userDataSource, false)
    }

    isLoggedIn() {
        return this.entity.id !== 0
    }

    getTokenState() {
        return this.entity.emailToken || 'provided'
    }

    async requestToken(email, password) {
        try {
            this.clearLastAction()
            await this.getDataSource().callCollectionCommand('requestToken', email, password)
            this.entity.emailToken = 'required'
        } catch (ex) {
            this.handleException(ex)
        }
    }

    async login(email, password, token) {
        try {
            this.clearLastAction()
            this.entity = await this.getDataSource().callCollectionCommand('login', email, password, token || '')
        } catch (ex) {
            this.handleException(ex)
        } finally {
            this.updateComputed()
        }
    }

    async logout() {
        this.clearLastAction()
        await this.getDataSource().callEntityCommand(this.entity.id, 'logout')
        this.entity = { id: 0 }
        this.updateComputed()
    }

    async logoutDeleted() {
        this.clearLastAction()
        this.entity = { id: 0 }
        this.updateComputed()
    }

    async resetPassword(email) {
        this.clearLastAction()
        await this.getDataSource().callCollectionCommand('resetPassword', email)
    }

    getEmail() {
        return [this.entity.email || ''].join(' ').trim()
    }

    isAdmin() {
        return this.entity.admin
    }
}
