import Entity from './Entity.js'
import DataConsumer from './DataConsumer.js'
import {DataException} from './DataSource.js'

let globalKey = 0

export default class FormModel extends DataConsumer {
    constructor(dataSource, operation, originalEntity, entity) {
        super(dataSource instanceof FormModel ? dataSource.getDataSource() : dataSource)
        this.parentModel = dataSource instanceof FormModel ? dataSource : null
        this.operation = operation
        this.originalEntity = originalEntity || {}
        this.entity = entity || Entity.clone(this.originalEntity)
        this.constraints = [
            {
                check: () =>
                    this.getAllSubModels().reduce((valid, m) => valid && m.isValid(), true) &&
                    Object.keys(this.properties).reduce(
                        (valid, p) => valid && this.isPropertyValid(p),
                        true
                    )
            }
        ]
        this.serializers = {}
        this.properties = {}
        this.subModels = {}
        this.edits = {}
        this.hasBeenSent = false
        this.key = globalKey++
    }

    onDataSourceChanged(dataSource, operation, id) {
        super.onDataSourceChanged(dataSource, operation, id)
        // Muoahahahahahahahahaaaaaaaaaaaaa
    }

    getParentModel() {
        return this.parentModel
    }

    getMainModel() {
        return this.parentModel ? this.parentModel.getMainModel() : this
    }

    onPrepareInboundEntity(preparator) {
        this.prepareInboundEntity = preparator
        this.originalEntity = Entity.clone(this.originalEntity)
        preparator(this.originalEntity)
        this.entity = Entity.clone(this.originalEntity)
    }

    prepareInbound(entity) {
        this.entity = entity
        if (this.prepareInboundEntity) this.prepareInboundEntity(this.entity)
        this.subModels = {}
    }

    onPrepareOutboundEntity(preparator) {
        this.prepareOutboundEntity = preparator
    }

    prepareOutbound() {
        const entity = Entity.clone(this.entity)
        if (this.prepareOutboundEntity) this.prepareOutboundEntity(entity)
        return entity
    }

    getSubModel(property) {
        let subModel = this.subModels[property]
        if (!subModel) {
            subModel = new FormModel(this, this.operation, this.originalEntity[property] || {})
            this.subModels[property] = subModel
            this.getSubModelConfigurator(property)(subModel)
        }
        return subModel
    }

    getSubModels(property) {
        let subModels = this.subModels[property]
        if (!subModels) {
            this.subModels[property] = subModels = []
            const originalEntities = this.originalEntity[property] || []
            const entities = this.entity[property] || []
            this.entity[property] = entities
            for(const [index, originalEntity] of originalEntities.entries()) {
                const subModel = new FormModel(this, this.operation, originalEntity, entities[index])
                subModels.push(subModel)
                this.getSubModelConfigurator(property)(subModel)
            }
        }
        return [...subModels]
    }

    insertNewSubModel(property, index) {
        let subModels = this.subModels[property]
        if(!Array.isArray(this.entity[property])) this.entity[property] = []
        const entity = {}
        this.entity[property].splice(index, 0, entity)
        const subModel = new FormModel(this, this.operation, {}, entity)
        subModels.splice(index, 0, subModel)
        return subModel
    }

    appendNewSubModel(property) {
        return this.insertNewSubModel(property, this.entity[property].length)
    }

    removeSubModel(property, index) {
        let subModels = this.subModels[property]
        this.entity[property].splice(index, 1)
        return subModels.splice(index, 1)[0]
    }

    getAllSubModels() {
        const allSubModels = []
        for(let propertySubModels of Object.values(this.subModels)) {
            if (Array.isArray(propertySubModels)) {
                for(let subModel of propertySubModels) {
                    allSubModels.push(subModel)
                }
            }
        }
        return allSubModels
    }

    configure(properties, options) {
        if (options instanceof Object) {
            properties = properties.split(',')
            for (let property of properties) {
                if (!this.properties[property]) {
                    this.properties[property] = {}
                }
                Object.assign(this.properties[property], options)
            }
        }
    }

    getConfig(property, attribute) {
        const propertyConfig = this.properties[property] || {}
        return attribute ? propertyConfig[attribute] : propertyConfig
    }

    hasConfig(property, attribute) {
        return this.properties[property] && this.properties[property].hasOwnProperty(attribute)
    }

    getEntity() {
        return this.entity
    }

    getOriginalEntity() {
        return this.originalEntity
    }

    refreshEntityFromOriginal() {
        this.entity = Entity.clone(this.originalEntity)
    }

    getOperation() {
        return this.operation
    }

    setOperation(operation) {
        return this.operation = operation
    }

    isViewing() {
        return this.operation === 'view'
    }

    isCreating() {
        return this.operation === 'create'
    }

    isUpdating() {
        return this.operation === 'update'
    }

    setSubModelConfigurator(properties, subModelConfigurator) {
        this.configure(properties, { subModelConfigurator })
    }

    getSubModelConfigurator(property) {
        return this.getConfig(property, 'subModelConfigurator') || (() => {})
    }

    addProperty(property, getter, setter) {
        this.configure(property, {
            getter,
            setter
        })
    }

    addGeneralConstraint(constraint) {
        this.constraints.push({check: constraint})
    }

    addConstraint(property, constraint) {
        this.constraints.push({
            property: property,
            check: constraint
        })
    }

    onChange(property, handler) {
        let handlers = this.getConfig(property, 'onChange')
        if (!handlers) handlers = []
        handlers.push(handler)
        this.configure(property, {'onChange' : handlers})
    }

    onValidChange(property, handler) {
        let handlers = this.getConfig(property, 'onValidChange')
        if (!handlers) handlers = []
        handlers.push(handler)
        this.configure(property, {'onValidChange' : handlers})
    }

    options(properties, options) {
        this.configure(properties, { options })
    }

    getOptions(property) {
        return this.getConfig(property, 'options')
    }

    mandatory(properties, message) {
        const options = { mandatory: true }
        if (message) options.mandatoryMessage = message
        this.configure(properties, options)
    }

    isMandatory(property) {
        return this.getConfig(property, 'mandatory')
    }

    allowPartial(properties) {
        this.configure(properties, { allowPartial: true })
    }

    allowsPartial(property) {
        return this.getConfig(property, 'allowPartial')
    }

    getMandatoryMessage(property) {
        return this.isMandatory(property) &&
            (this.getConfig(property, 'mandatoryMessage') || 'Fehlende Angabe')
    }

    readOnly(properties) {
        this.configure(properties, { readOnly: true })
    }

    isReadOnly(property) {
        return this.operation === 'view' || this.getConfig(property, 'readOnly')
    }

    mutable(properties) {
        this.configure(properties, { readOnly: false })
    }

    isMutable(property) {
        return !this.isReadOnly(property)
    }

    sendOnly(properties) {
        this.configure(properties, { sendOnly: true })
    }

    isSendOnly(property) {
        return this.getConfig(property, 'sendOnly')
    }

    nullable(properties, nullable) {
        nullable = nullable === undefined ? true : nullable
        this.configure(properties, { nullable })
    }

    isNullable(property) {
        return this.getConfig(property, 'nullable')
    }

    emptyValue(properties, value) {
        this.configure(properties, { emptyValue: value })
    }

    getEmptyValue(property) {
        return this.getConfig(property, 'emptyValue')
    }

    hasValue(property) {
        return this.entity.hasOwnProperty(property)
    }

    isNull(property) {
        return !this.entity.hasOwnProperty(property) ||
            this.entity[property] === null ||
            this.entity[property] === undefined
    }

    isEmpty(property) {
        return this.valueIsEmpty(this.entity[property])
    }

    setAlwaysTainted(alwaysTainted) {
        this.alwaysTainted = alwaysTainted
    }

    isTainted() {
        return this.alwaysTainted || !Entity.equal(this.originalEntity, this.entity)
    }

    isValid() {
        return this.constraints.reduce((valid, constraint) => valid && (constraint.check(this.entity) === true), true)
    }

    isPropertyValid(property) {
        return this.constraints
            .filter(c => c.property === property)
            .reduce((valid, constraint) => valid && (constraint.check(this.entity) === true), true) &&
            (!this.isMandatory(property) || !this.isEmpty(property)) &&
            !this.entity[property + ':problems']
    }

    getPropertyProblems(property) {
        return this.constraints
            .filter(c => c.property === property)
            .map(c => c.check(this.entity))
            .filter(r => r !== true)
            .concat(
                (this.isMandatory(property) && this.isEmpty(property)) ?
                    [this.getMandatoryMessage(property)] :
                    [],
                this.entity[property + ':problems'] || []
            )
    }

    addGeneralProblem(problem) {
        this.entity['*:problems'] = this.getGeneralProblems().concat([problem])
    }

    getPropertyProblemText(property) {
        return this.getPropertyProblems(property).join(' / ')
    }

    getGeneralProblems() {
        return this.constraints
            .filter(c => !c.property)
            .map(c => c.check(this.entity))
            .filter(r => typeof r === 'string')
            .concat(this.entity['*:problems'] || [])
    }

    hasGeneralProblems() {
        return this.getGeneralProblems().length > 0
    }

    getGeneralProblemText(property) {
        return this.getGeneralProblems().join(' / ')
    }

    addGeneralWarning(warning) {
        this.entity['*:warnings'] = this.getGeneralWarnings().concat([warning])
    }

    getGeneralWarnings() {
        return this.entity['*:warnings'] || []
    }

    getGeneralWarningText(property) {
        return this.getGeneralWarnings().join(' / ')
    }

    hasGeneralWarnings() {
        return this.getGeneralWarnings().length > 0
    }

    canSave() {
        return this.isValid() && this.isTainted()
    }

    serialize(property, serializer) {
        this.serializers[property] = serializer
    }

    getValue(property) {
        const getter = this.getConfig(property, 'getter')
        return getter ? getter() : this.entity[property]
    }

    getOriginalValue(property) {
        return this.originalEntity[property]
    }

    valueIsEmpty(value) {
        return (
            value === undefined ||
            value === null ||
            (typeof value === 'string' && value.length === 0) ||
            (typeof value === 'number' && isNaN(value))
        )
    }

    dispatchChangeEvents(property, oldValue) {
        const value = this.getValue(property)
        if (oldValue !== value) {
            let handlers = this.getConfig(property, 'onChange')
            if (handlers) {
                for(let handler of handlers) {
                    handler(value, oldValue)
                }
            }
            if (this.isPropertyValid(property)) {
                handlers = this.getConfig(property, 'onValidChange')
                if (handlers) {
                    for(let handler of handlers) {
                        handler(value, oldValue)
                    }
                }
            }
        }
    }

    setValue(property, value) {
        if (!this.isReadOnly(property)) {
            const setter = this.getConfig(property, 'setter')
            const oldValue = this.getValue(property)
            if (setter) {
                setter(value)
                this.setEdited(property, true)
            } else {
                let newValue = this.serializers[property] ? this.serializers[property](value) : value
                if (this.hasConfig(property, 'emptyValue') && this.valueIsEmpty(newValue)) {
                    newValue = this.getEmptyValue(property)
                }
                if (newValue === undefined) {
                    if (this.entity.hasOwnProperty(property)) {
                        delete this.entity[property]
                        this.setEdited(property, true)
                    }
                } else if (this.entity[property] !== newValue) {
                    this.entity[property] = newValue
                    this.setEdited(property, true)
                }
            }
            this.dispatchChangeEvents(property, oldValue)
        }
    }

    touch(property) {
        this.edits[property] = true
    }

    getEdited(property) {
        return this.edits[property]
    }

    removePropertyProblems(property) {
        if (this.entity[property + ':problems']) {
            delete this.entity[property + ':problems']
        }
        if (this.entity[property + ':warnings']) {
            delete this.entity[property + ':warnings']
        }
    }

    removeGlobalProblems() {
        if (this.entity['*:problems']) {
            delete this.entity['*:problems']
        }
        if (this.entity['*:warnings']) {
            delete this.entity['*:warnings']
        }
    }

    setEdited(property, edited) {
        edited = edited === undefined ? true : Boolean(edited)
        if (this.edits[property] !== edited) {
            this.edits[property] = edited
            if (edited) {
                this.removePropertyProblems(property)
                this.removeGlobalProblems()
            }
        }
    }

    getArray(property) {
        return Array.isArray(this.entity[property]) ? this.entity[property] : []
    }

    getOriginalArray(property) {
        return Array.isArray(this.originalEntity[property]) ? this.originalEntity[property] : []
    }

    setArray(property, array, preserveOriginalOrder) {
        if (!this.isReadOnly(property)) {
            const originalArray = this.getOriginalArray(property)
            let edited = false
            let newArray
            if (preserveOriginalOrder) {
                newArray = []
                for (let value of originalArray) {
                    if (array.findIndex(v => v === value) >= 0) {
                        newArray.push(value)
                    }
                }
                edited = originalArray.length !== newArray.length
                for (let value of array) {
                    if (originalArray.findIndex(v => v === value) < 0) {
                        newArray.push(value)
                        edited = true
                    }
                }
            } else {
                newArray = array
                const intersecting = array.filter(v => originalArray.includes(v)).length
                edited = array.length !== intersecting || originalArray.length !== intersecting
            }
            this.entity[property] = newArray
            if (edited) this.setEdited(property, true)
        }
    }

    isEdited() {
        return Object.keys(this.edits).length > 0
    }

    isPropertyEdited(property) {
        return this.edits[property]
    }

    setNotEdited() {
        this.edits = {}
    }

    setAllEdited() {
        for (const property of Object.keys(this.properties)) {
            this.edits[property] = true
        }
    }

    shouldShowPropertyProblems(property) {
        return (this.edits[property] || (!this.isSendOnly(property) && this.hasBeenSent)) &&
            !this.isPropertyValid(property)
    }

    shouldShowGeneralProblems() {
        return this.hasBeenSent && this.getGeneralProblems().length > 0
    }

    setOnSave(saveOperation) {
        this.saveOperation = saveOperation
    }

    async onSave(entity, ignoreWarnings) {
        if (this.saveOperation) {
            return await this.saveOperation(entity, ignoreWarnings)
        } else if (this.isCreating()) {
            return this.dataSource.create(entity, ignoreWarnings)
        } else if (this.isUpdating()) {
            return this.dataSource.update(entity, ignoreWarnings)
        }
    }

    async save(ignoreWarnings) {
        if (this.parentModel) {
            return this.getMainModel().save(ignoreWarnings)
        }
        if (!this.dataSource) {
            throw new DataException(500, 'no data source', null)
        }
        this.hasBeenSent = true
        this.setNotEdited()
        this.removeGlobalProblems()
        try {
            this.prepareInbound(await this.onSave(this.prepareOutbound(), ignoreWarnings))
        } catch (ex) {
            const {code, message, entity} = ex
            if (code === 400 && entity) {
                this.prepareInbound(entity)
            } else {
                throw ex
                this.addGeneralProblem('Problem bei der Übertragung (Fehler ' + code + '): ' + message)
            }
            return (this.hasGeneralWarnings() && this.isValid()) ? 'warnings' : 'problems'
        }
        return 'ok'
    }
}
