import {
    m,
    Button
} from './base.js'
import { Layout } from './layout.js'

export class Modal {

    static view() {
        const layers = []
        for(const modal of Object.values(Modal.modals)) {
            layers.push(modal.view())
        }
        if (layers.length > 0) {
            layers.splice(layers.length - 1, 0, m(
                '.fixed' +
                '.w-full.h-full' +
                '.bg-black.opacity-50' +
                '.top-0'
            ))
            return m(
                '.fixed' +
                '.w-full.h-full' +
                '.z-1' +
                '.top-0',
                { tabIndex: 0 },
                layers
            )
        }
        return null;
    }

    static alert(message, options) {
        options = options || {}
        const modal = new Modal({
            title: options.title,
            accept: {
                kind: options.kind || 'primary',
                label: options.label || 'OK'
            },
            renderContent: () => m('p', message)
        })
        return modal.show()
    }

    static confirm(message, options) {
        options = options || {}
        const modal = new Modal({
            title: options.title,
            accept: {
                kind: options.acceptKind || 'primary',
                label: options.acceptLabel || 'OK'
            },
            cancel: {
                kind: options.cancelKind || 'primary',
                label: options.cancelLabel || 'Abbrechen'
            },
            renderContent: () => m('p', message)
        })
        return modal.show()
    }

    constructor(options) {
        this.options = Object.assign({}, options)
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    show() {
        Modal.modals.push(this)
        m.redraw()
        return this.promise
    }

    hide(action) {
        Modal.modals = Modal.modals.filter(item => item !== this)
        m.redraw()
        this.resolve && this.resolve(action || 'code')
    }

    renderContent() {
        return this.options.renderContent ? this.options.renderContent() : null
    }

    checkHide(action, result) {
        Promise.resolve(result).then(r => {
            if (r !== false) {
                this.hide(action)
            }
        })
    }

    onClose() {
        this.checkHide('close', this.options.close.action ? this.options.close.action() : true)
    }

    onAccept() {
        this.checkHide('accept', this.options.accept.action ? this.options.accept.action() : true)
    }

    onSecondary() {
        this.checkHide('secondary', this.options.secondary.action ? this.options.secondary.action() : true)
    }

    onCancel() {
        this.checkHide('cancel', this.options.cancel.action ? this.options.cancel.action() : true)
    }

    getDisabled(buttonConfig) {
        if (buttonConfig.disabled === true || buttonConfig.disabled === false) return buttonConfig.disabled
        return buttonConfig.disabled ? buttonConfig.disabled() : false
    }



    view() {
        return m('.absolute.w-full.h-full.flex.py-6',
            m(
                '.m-auto' +
                '.bg-white.rounded.shadow-lg' +
                '.flex.flex-col' +
                '.relative.max-h-full',
                {
                    style: {
                        width: 500 + 'pt'
                    }
                },
                [
                    this.options.close && m(
                        'button' +
                        '.absolute.right-0.top-0.px-6.py-3' +
                        '.font-4xl.font-bold' +
                        '.focus:outline-none',
                        {
                            onclick: () => this.onClose()
                        },
                        m.trust('&times;')
                    ),
                    this.options.title && m(
                        '.px-6.py-3' +
                        '.text-xl.font-bold' +
                        '.border-b.border-gray-light',
                        this.options.title || ''
                    ),
                    m(
                        '.px-6.py-3.overflow-y-auto',
                        this.renderContent(),
                    ),
                    m(
                        '.px-6.py-1' +
                        '.border-t.border-gray-light',
                        m('.flex.flex-row.justify-between', [
                            this.options.renderInfo ? m('div.flex.justify-start.m-2', this.options.renderInfo()) : m('div'),
                            m('div.flex.justify-end.m-2', m(Layout.PaddedHBox, [
                                this.options.cancel && m(Button, {
                                    kind: this.options.cancel.kind || 'secondary',
                                    onclick: () => this.onCancel()
                                }, this.options.cancel.label || 'Cancel'),
                                this.options.secondary && m(Button, {
                                    kind: this.options.secondary.kind || 'secondary',
                                    disabled: this.getDisabled(this.options.secondary),
                                    onclick: () => this.onSecondary()
                                }, this.options.secondary.label || '...'),
                                this.options.accept && m(Button, {
                                    kind: this.options.accept.kind || 'primary',
                                    disabled: this.getDisabled(this.options.accept),
                                    onclick: () => this.onAccept()
                                }, this.options.accept.label || 'OK')
                            ]))
                        ])
                    )
                ]
            )
        )
    }
}

Modal.modals = []
