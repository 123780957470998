
class PseudoWeakRef {
    constructor(obj) {
        this.obj = obj
    }

    deref() {
        return this.obj
    }
}


export class ObjectEvent {
    constructor(source) {
        this.source = source
        this.consumers = []
        this.dispatching = false
    }

    createWeakRef(obj) {
        if (window.WeakRef) {
            return new WeakRef(obj)
        } else {
            return new PseudoWeakRef(obj)
        }
    }

    isWeakRef(obj) {
        if (window.WeakRef) {
            return obj instanceof WeakRef
        } else {
            return obj instanceof PseudoWeakRef
        }
    }

    register(obj, fun) {
        this.consumers.push(fun ? [this.createWeakRef(obj), fun] : [obj, undefined])
    }

    unregister(obj, fun) {
        this.consumers = this.consumers.filter(
            c => (this.isWeakRef(c[0]) ? c[0].deref() : c[0]) !== obj && c[1] !== fun
        )
    }

    dispatch(...args) {
        if (!this.dispatching) { // protection against event cycling
            try {
                this.dispatching = true
                const newConsumers = []
                for (let consumerRef of this.consumers) {
                    let [obj, fun] = consumerRef
                    obj = this.isWeakRef(obj) ? obj.deref() : obj
                    if (obj) {
                        newConsumers.push(consumerRef)
                        if (typeof obj === 'function') {
                            obj(this.source, ...args)
                        } else if (fun && typeof obj[fun] === 'function') {
                            obj[fun](this.source, ...args)
                        }
                    }
                }
                this.consumers = newConsumers
            } catch(ex) {
                console.log('checkAvail', ex)
            } finally {
                this.dispatching = false
            }
        }
    }
}
