
export default class Entity {
    static clone(entity) {
        return Entity.postprocess(JSON.parse(JSON.stringify(entity)))
    }

    static _visitProperties(entity, visitor) {
        if (Array.isArray(entity) || (entity instanceof Object && !(entity instanceof Date))) {
            for(const [property, subEntity] of Object.entries(entity)) {
                let [operation, data] = visitor(entity, property, subEntity)
                if (operation === 'delete') {
                    delete entity[property]
                } else if (operation === 'return') {
                    return [operation, data]
                } else if (operation === 'set') {
                    entity[property] = data
                } else {
                    this._visitProperties(subEntity, visitor)
                }
            }
        }
        return [undefined, undefined]
    }

    static visitProperties(entity, visitor) {
        let [operation, data] = Entity._visitProperties(entity, visitor)
        return data
    }

    static visitMeta(entity, visitor) {
        Entity.visitProperties(entity, (e, p, v) => {
            const [property, meta] = p.split(':')
            if (meta) {
                return visitor(e, property, meta, v)
            }
            return []
        })
    }

    static postprocess(entity) {
        Entity.visitMeta(entity, (e, p, m, v) => {
            if (m === 'type' && v === 'date') {
                e[p] = e[p] && new Date(e[p])
            }
            return []
        })
        return entity
    }

    static preprocess(entity) {
        Entity.visitProperties(entity, (e, p, v) => {
            if (v instanceof Date) {
                e[p] = v.toISOString()
                e[p + ':type'] = 'date'
            }
            return []
        })
        return entity
    }

    static serialize(entity) {
        return JSON.stringify(Entity.preprocess(entity))
    }

    static equal(entity1, entity2) {
        if (entity1 === entity2) return true
        if (entity1 instanceof Date) {
            if (!(entity2 instanceof Date)) return false
            return entity1.getTime() === entity2.getTime()
        }
        if (Array.isArray(entity1)) {
            if (!Array.isArray(entity2) || entity1.length !== entity2.length) return false
            for(let i = 0; i < entity1.length; i++) {
                if (!Entity.equal(entity1[i], entity2[i])) return false
            }
            return true
        }
        if (entity1 instanceof Object) {
            if (!(entity2 instanceof Object)) return false
            for(const key of Object.keys(entity1)) {
                if (!entity2.hasOwnProperty(key)) return false
            }
            for(const key of Object.keys(entity2)) {
                if (!Entity.equal(entity1[key], entity2[key])) return false
            }
            return true
        }
        return false
    }

    static getPropertyProblems(entity, property) {
        return entity[property + ':problems'] || []
    }

    static getPropertyProblemText(entity, property) {
        return this.getPropertyProblems(entity, property).join(' / ')
    }

    static addGeneralProblem(entity, problem) {
        entity['*:problems'] = this.getGeneralProblems().concat([problem])
    }

    static getGeneralProblems(entity) {
        return entity['*:problems'] || []
    }

    static getGeneralProblemText(entity, property) {
        return this.getGeneralProblems(entity).join(' / ')
    }

    static hasGeneralProblems(entity) {
        return this.getGeneralProblems(entity).length > 0
    }

    static addGeneralWarning(entity, warning) {
        entity['*:warnings'] = this.getGeneralWarnings(entity).concat([warning])
    }

    static getGeneralWarnings(entity) {
        return entity['*:warnings'] || []
    }

    static getGeneralWarningText(entity) {
        return this.getGeneralWarnings(entity).join(' / ')
    }

    static hasGeneralWarnings(entity) {
        return this.getGeneralWarnings(entity).length > 0
    }
}
