import {
    Button,
    m
} from '../../n2/components/base.js'

import {MainHeadline} from '../../shared/components/MainComponents.js'

import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

export default class PrivacyPage extends AppPage {
    renderChildren() {
        return m('.probands-list.mt-5.w-full.lg:w-3/4.xl:w-3/5.mx-auto', [
                m(MainHeadline, {title: 'Datenschutzerklärung'}),
                m('hr.border-gray-darkest.my-2'),
            ])
    }
}
