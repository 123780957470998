import { m, Button, Control, Icon } from '../../n2/components/base.js'

const menuItemClasses = 'block py-1 px-2 cursor-pointer text-white font-bold hover:underline'

export default class AppFooter extends Control {
    constructor(vnode) {
        super(vnode, 'footer')
        this.showDropdown = false
    }

    getUser() {
        return this.attrs.user || {}
    }

    async onLogout(e) {
        e.preventDefault();
        await this.getUser().logout().then(() => {
            m.route.set('/login')
        });
    }

    getMenuItems() {
        return this.attrs.menuItems || []
    }

    renderMenuItem(mi) {
        return m('a',
                {
                    class: menuItemClasses,
                    href: mi.href,
                    target: mi.target
                },
                mi.title
            )
    }

    renderHeader() {
        const menuItems = this.getMenuItems()
        //const copyright = '©' //m('span', m.trust("&copy;"))
        return m('div', { class: 'flex flex-wrap flex-row justify-between items-center md:space-x-4 bg-brand1 px-6 relative border-t border-gray-lightest' }, [
            m('p.text-white', m.trust('powered by terminblock.de / Ein Service von <a href="https://www.nextcontrol.de" title="NEXTcontrol GmbH" target="_blank">nextcontrol</a>')),
            m('nav', {
                class: 'z-20 flex flex-row'
            },
                menuItems.map(mi => this.renderMenuItem(mi))
            ),

        ])
    }

    renderChildren() {
        return [ this.renderHeader(), ...this.vnode.children ]
    }
}
