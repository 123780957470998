import {Theme, Button, Icon, m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'
import ProbandForm from '../components/ProbandForm.js'
import {
    CalendarIcon,
    UsersIcon
} from '../../n2/icons.js'

import {
    ExpandedCard,
    MainHeadline,
    BackButton
} from '../../shared/components/MainComponents.js'
import Card from '../../shared/components/Card.js'

import { toDateString, toTimeString } from '../../n2/dateTime.js'
import {deleteEntity} from '../../n2/components/crud.js'

//import QRCode from '../../../js/vendor/qrcode-min.js'

import {
    CogIcon
} from '../../n2/icons.js'

export default class TestsPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    confirmDelete(test) {
        return deleteEntity(App.tests, test.id, 'Test vom ' + toDateString(test.createdAt), true)
    }


    renderTests() {
        const tests = App.tests.all.array
        if (tests.length > 0) {
            return tests.sort((a, b) => b.testTime - a.testTime).map(a =>
                    m(Card,
                        {
                            title: 'Test vom: ' + toDateString(a.createdAt),
                            subtitle: a.probandFirstName + ' ' +  a.probandLastName,
                            download:  App.tests.getEntityCommandGetUrl(a.id, 'loadDocument'),
                            cardAction: '/tests/' + a.id,
                            size: 'lg',
                            delete: () => this.confirmDelete(a),
                        }
                    ),
                )
        } else {
            return m('p.text-xl.text-gray.text-center', 'Es gibt noch keine Tests.')
        }
    }


    renderBackButton() {
        return m('.flex.justify-start.my-4',
            m(BackButton, {backlink: '/probands'}),
        )
    }

    renderChildren() {
        return [
            m(MainHeadline, {title: 'Testübersicht'}),
            m('.flex.w-full.sm:w-1/2', [
                m(Card,
                    {
                        title: 'Tests aktualisieren',
                        icon: CogIcon,
                        cardAction: () => App.tests.reloadAll(),
                        size: 'lg',
                    }
                ),
            ]),
            m('hr.border-gray-darkest.my-2'),
            this.renderTests(),
        ]
    }
}
