import { m, Button, Control } from '../../n2/components/base.js'
import App from '../state/App.js'

const menuItemClasses = 'block py-1 cursor-pointer text-gray-darkest hover:underline'

const breadcrumbItemClasses = 'block p-1 cursor-pointer text-gray hover:underline'

export default class AppMenu extends Control {
    constructor(vnode) {
        super(vnode, 'header')
        this.showDropdown = false
    }

    getUser() {
        return this.attrs.user || {}
    }

    async onLogout(e) {
        e.preventDefault();
        await this.getUser().logout().then(() => {
            m.route.set('/login')
        });
    }

    //Add more reliable function later
    setDropdown(onlyClose) {
        if (onlyClose) {
            setTimeout(() => {
                this.showDropdown = false;
                m.redraw()
            }, 200);
        } else {
            this.showDropdown = !this.showDropdown
        }
    }

    getMenuItems() {
        return this.attrs.menuItems || []
    }

    getBreadcrumbItems() {
        let here = location.href.replace('#/', '')
        here = here.split('/').slice(3)
        let user = false

        let parts = [{ "title": 'Startseite', "divider": (here.length > 1) ? true : false, "href": '/' }]
        if (here.length > 0) {
            for( let i = 0; i < (here.length-1); i++ ) {
                let part = here[i]
                if (part == 'probands') {
                    user = true
                }
                if (!isNaN(part)) {
                    const data = App.probands.all.byId[parseInt(part)]
                    if (data !== undefined) {
                        part = data.firstName + ' ' + data.lastName
                    }
                }
                part = part.replace('probands', 'Testpersonen')
                part = part.replace('tests', 'Tests')
                part = part.replace('bookings', 'Termine')
                let divider = true
                if (part != '#') {
                    const title = part
                    divider = (i == (here.length-2)) ? false : true
                    const href = '/' + here.slice( 0, i + 1 ).join('/')
                    parts.push({ "title": title, 'divider': divider, "href": href })
                }
            }
        } else {
            parts = false
        }
        return parts
    }

    renderMenuItem(mi) {
        return mi.onClick ?
            m(
                'div',
                {
                    class: menuItemClasses,
                    onclick: (vnode) => mi.onClick(vnode, mi)
                },
                mi.title
            ) :
            m(
                m.route.Link,
                {
                    class: menuItemClasses,
                    href: mi.href
                },
                mi.title
            )
    }

    renderBreadcrumbItem(mi) {
        //Breadcrumbs based on URL location
        return m('span.flex.items-center',
                m(
                    m.route.Link,
                    {
                        class: breadcrumbItemClasses,
                        href: mi.href
                    },
                    mi.title
                ),
                m('span.text-gray', ((mi.divider) ? '»' : ''))
            )
    }

    renderHeader() {
        const menuItems = this.getMenuItems()
        return m('div', { class: 'flex flex-wrap flex-row justify-between items-center md:space-x-4 bg-white py-6 px-6 relative border-b border-gray-lightest' }, [
            m(m.route.Link, {
                class: 'block cursor-pointer text-white',
                href: '/home'
            }, m('img', {
                class: 'h-6 md:h-8',
                src: '/assets/logo-left.svg'
            })),
            m('Button', {
                class: 'inline-block md:hidden w-8 h-8 bg-white text-brand1 p-1 rounded',
                onclick: () => this.setDropdown(false),
                onfocusout: () => this.setDropdown(true),
            }, [
                m('svg', {
                    fill: 'currentColor',
                    viewBox: '0 0 20 20',
                }, [
                    m('path', {
                        fillRule: "evenodd",
                        d: 'M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z',
                        clipRule: 'evenodd',
                    }),
                ])
            ]),
            m('nav', {
                class: (this.showDropdown ? 'flex' : 'hidden md:flex') + ' absolute md:relative top-16 left-0 md:top-0 z-20 flex flex-col md:flex-row md:space-x-6 w-full md:w-auto bg-white shadow-md md:shadow-none md:bg-transparent px-6 py-3  md:p-0 border-t border-gray-lightest md:border-0'
            },
                menuItems.map(mi => this.renderMenuItem(mi))
            ),

        ])
    }

    renderBreadcrumb() {
        const menuItems = this.getBreadcrumbItems()
        return menuItems && m('div', { class: 'flex flex-wrap flex-row justify-left items-center md:space-x-4 bg-gray-lightest py-3 px-2 relative' }, [
            m('div.w-full lg:w-3/4 xl:w-3/5 mx-auto',
                m('nav.flex.text-lg',
                    menuItems.map(mi => this.renderBreadcrumbItem(mi))
                ),
            )
        ])

    }

    renderChildren() {
        return [ this.renderHeader(), this.renderBreadcrumb(), ...this.vnode.children ]
    }
}
