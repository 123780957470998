import {Theme, Button, Icon, m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'
import ProbandForm from '../components/ProbandForm.js'
import {
    CalendarIcon,
    UsersIcon,
    QrIcon
} from '../../n2/icons.js'

import {
    ExpandedCard,
    MainHeadline,
    BackButton
} from '../../shared/components/MainComponents.js'
import Card from '../../shared/components/Card.js'
import {toDateString, toTimeString} from '../../n2/dateTime.js'
import cancelEntity from '../../shared/components/CancelEntity.js'
import BookingsPage from './BookingsPage.js'

export default class ProbandPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    getOperation() {
        return this.attrs.operation || 'view'
    }

    getEntityId() {
        return m.route.param('proband') || 0
    }

    getName() {
        return (this.getEntityId() > 0 && App.probands.all.byId[this.getEntityId()]) ? App.probands.all.byId[this.getEntityId()].name : 'unbekannt'
    }

    getSelfTest() {
        return this.attrs.selftest || false
    }

    renderIconCards() {
        const iconCards = [
            {
                title: 'Neuen Testtermin buchen',
                icon: CalendarIcon,
                cardAction: m.route.get() + '/booking',
                active: true,
            },
            {
                title: 'Persönliche Daten bearbeiten',
                icon: UsersIcon,
                cardAction: m.route.get() + '/edit',
                active: true,
            },
            {
                title: 'Selbsttest starten',
                icon: QrIcon,
                cardAction: m.route.get() + '/self-test',
                active: this.getSelfTest(),
            }
        ]
        return m('div.flex.flex-wrap',
            iconCards.map(a =>
            a.active ?
                m(Card,
                    {
                        title: a.title,
                        subtitle: a.subtitle,
                        icon: a.icon,
                        cardAction: a.cardAction,
                        size: a.size,
                    }
                ) : ''
            ),
        )
    }

    async cancelBooking(booking) {
        return cancelEntity(App.bookings, booking.id, "Termin am " + toDateString(booking.startTime), true)
    }

    renderBookings() {
        const bookings = App.bookings.all.with('proband', this.getEntityId())
        if (bookings.length > 0) {
            return m(ExpandedCard, { title: 'Testtermine', },
                BookingsPage.renderBookings(bookings, new Date())
            )
        }
    }

    renderTests() {
        const tests = App.tests.all.with('proband', this.getEntityId())
        if (tests.length > 0) {
            return m(ExpandedCard,
                {
                    title: 'Tests',
                },
                tests.map(a =>
                    m(Card,
                        {
                            title: 'Test vom: ' + toDateString(a.createdAt),
                            subtitle: a.probandFirstName + ' ' +  a.probandLastName,
                            download:  App.tests.getEntityCommandGetUrl(a.id, 'loadDocument'),
                            cardAction: '/tests/' + a.id,
                            size: 'lg',
                            delete: '/delete'
                        }
                    ),
                )
            )
        }
    }

    renderDetails() {
        return m(ExpandedCard,
            {
                title: 'Persönliche Daten',
            },
            m('div.bg-gray-lightest.border.border-brand1.p-2',
                this.renderEditForm(),
            ),
        )
    }

    renderEditForm() {
        const operation = this.getOperation()
        return m('div',
            m(ProbandForm, {
                operation: this.getOperation(),
                entityId: this.getEntityId()
            }),
            operation === 'view' && [
                m('.flex.justify-end.mt-4', [
                    m(Button, {
                        class: 'rounded-sm font-bold py-1 px-2',
                        onclick: () => m.route.set('/probands/' + this.getEntityId() + '/edit')
                    }, 'Bearbeiten'),
                ])
            ]
        )
    }

    renderBackButton() {
        return m('.flex.justify-start.my-4',
            m(BackButton, {backlink: '/probands'}),
        )
    }

    renderNote() {
        return m('div.w-full',
            m('p.font-bold.mb-4', 'Hinweis: Daten der neu angelegten Testperson einschließlich der Testergebnisse sind ausschließlich über das persönliche Nutzerkonto zugänglich. Die neu angelegte Testperson hat keinen separaten Zugriff auf Ihre Daten.'),
        )
    }

    renderChildren() {
        const operation = this.getOperation()
        return [
            m(MainHeadline, {title: ((operation === 'create') ? 'Neue Testperson anlegen' : 'Testperson: ' + this.getName())}),
            (operation === 'view') && [
                this.renderIconCards(),
                this.renderBookings(),
                this.renderTests(),
                this.renderDetails()
            ],
            operation === 'create' && this.renderNote(),
            (operation === 'update' || operation === 'create') && this.renderEditForm(),
            (operation === 'view') && this.renderBackButton(),
        ]
    }
}
