
import m from '../../vendor/mithril.js'
import Entity from './Entity.js'
import EntityCollection from './EntityCollection.js'
import {ObjectEvent} from '../ObjectEvent.js'

export class DataException {
    constructor(code, message, entity) {
        this.code = code
        this.message = message || 'Invalid request'
        this.entity = entity
    }
}

export default class DataSource {
    constructor(baseUrl, fetchAll) {
        this.baseUrl = baseUrl
        this.onInvalidate = new ObjectEvent(this)
        this.friends = []
        if (fetchAll) {
            this.once = []
            this.state = 'pending'
            this.all = new EntityCollection()
        }
    }

    addFriendSource(dataSource) {
        this.friends.push(dataSource)
    }

    static friends(...dataSources) {
        for (let dataSource of dataSources) {
            for (let targetDataSource of dataSources) {
                if (targetDataSource !== dataSource) {
                    targetDataSource.addFriendSource(dataSource)
                }
            }
        }
    }

    getBaseUrl() {
        return this.baseUrl
    }

    getEntityUrl(id) {
        return this.baseUrl + '/' + id
    }

    onceLoaded(callback) {
        if (!this.all || this.state === 'loaded') {
            callback()
        } else {
            this.once.push(callback)
        }
    }

    postprocess(entity) {
        try {
            return Entity.postprocess(entity)
        } catch (ex) {
            throw new DataException(400, 'Unable to read response data')
        }
    }

    async reloadAll() {
        if (this.all) {
            this.state = 'loading'
            await this.fetchAll()
            this.state = 'loaded'
            for (let callback of this.once) {
                callback()
            }
            this.once = []
        }
    }

    async invalidate(operation, id) {
        await this.reloadAll()
        this.onInvalidate.dispatch(operation, id)
        if (operation !== 'friend') {
            for(let friend of this.friends) {
                friend.invalidate('friend', 0)
            }
        }
    }

    async fetchAll() {
        if (this.all) {
            const result = await this.query({
                purpose: 'stock'
            })
            this.all.setEntities(result.entities)
        }
    }

    clearAll() {
        if(this.all) this.all.setEntities()
    }

    async load(id) {
        try {
            let result = await m.request({
                method: 'GET',
                url: this.getEntityUrl(id)
            })
            if (!result) throw new DataException(500, 'missing result')
            return await this.postprocess(result.data)
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            throw new DataException(code, response && response.reason)
        }
    }

    async create(entity, ignoreWarnings) {
        const params = ignoreWarnings ? { 'ignore-warnings': 'true' } : {}
        try {
            let result = await m.request({
                method: 'POST',
                url: this.baseUrl,
                params,
                body: entity,
                serialize: Entity.serialize
            })
            if (!result) throw new DataException(500, 'missing result')
            entity = this.postprocess(result.data)
            this.invalidate('create', entity.id)
            return entity
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            const de = new DataException(code)
            if (response) {
                if (response.data) {
                    de.entity = this.postprocess(response.data)
                }
                de.message = response.reason
            }
            throw de
        }
    }

    async update(entity, ignoreWarnings) {
        const params = ignoreWarnings ? { 'ignore-warnings': 'true' } : {}
        try {
            let result = await m.request({
                method: 'POST',
                url: this.getEntityUrl(entity.id),
                params,
                body: entity,
                serialize: Entity.serialize
            })
            if (!result) throw new DataException(500, 'missing result')
            entity = this.postprocess(result.data)
            this.invalidate('update', entity.id)
            return entity
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            const de = new DataException(code)
            if (response) {
                if (response.data) {
                    de.entity = this.postprocess(response.data)
                }
                de.message = response.reason
            }
            throw de
        }
    }

    async delete(id, ignoreWarnings) {
        const params = ignoreWarnings ? { 'ignore-warnings': 'true' } : {}
        try {
            await m.request({
                method: 'DELETE',
                url: this.getEntityUrl(id),
                params
            })
            this.invalidate('delete', id)
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            const de = new DataException(code)
            if (response) {
                if (response.data) {
                    de.entity = this.postprocess(response.data)
                }
                de.message = response.reason
            }
            throw de
        }
    }

    async query({pageSize, pageIndex, filters, orderBy, orderDirection, purpose}) {
        const params = {}
        if (pageSize !== undefined && pageIndex !== undefined) {
            params.pageSize = pageSize
            params.page = pageIndex
        }
        if (orderBy !== undefined) {
            params.orderBy = orderBy
            if (orderDirection !== undefined) {
                params.orderDirection = orderDirection
            }
        }
        if (filters !== undefined) {
            params.filters = filters
        }
        if (purpose !== undefined) {
            params.purpose = purpose
        }

        try {
            let result = await m.request({
                method: 'GET',
                url: this.baseUrl,
                params: {
                    json: Entity.serialize(params)
                }
            })
            if (!result) throw new DataException(500, 'missing result')
            const data = result.data
            if (data) {
                if (Array.isArray(data.entities)) {
                    data.entities = data.entities.map(entityData => this.postprocess(entityData))
                } else {
                    throw new DataException(400, 'Invalid response format')
                }
            } else {
                throw new DataException(400, 'Missing response data')
            }
            return data
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            throw new DataException(code, (response && response.reason))
        }
    }

    getCommandQueryParam(command, ...args) {
        return 'json=' + encodeURIComponent(Entity.serialize({command, args}))
    }

    getEntityCommandGetUrl(id, command, ...args) {
        return this.getEntityUrl(id) + '/call?' + this.getCommandQueryParam(command, ...args)
    }

    getCollectionCommandGetUrl(command, ...args) {
        return this.getBaseUrl() + '/call?' + this.getCommandQueryParam(command, ...args)
    }

    async callEntityCommand(id, command, ...args) {
        try {
            let result = await m.request({
                method: 'POST',
                url: this.getEntityUrl(id) + '/call',
                body: {
                    command,
                    args
                },
                serialize: Entity.serialize
            })
            if (!result) throw new DataException(500, 'missing result')
            return this.postprocess(result.data)
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            const de = new DataException(code)
            if (response) {
                de.message = response.reason
            }
            throw de
        }
    }

    async callCollectionCommand(command, ...args) {
        try {
            let result = await m.request({
                method: 'POST',
                url: this.getBaseUrl() + '/call',
                body: {
                    command,
                    args
                },
                serialize: Entity.serialize
            })
            if (!result) throw new DataException(500, 'missing result')
            return this.postprocess(result.data)
        } catch(ex) {
            if (ex instanceof DataException) throw ex
            const {code, response} = ex
            const de = new DataException(code)
            if (response) {
                de.message = response.reason
            }
            throw de
        }
    }
}
