import { m, Control } from '../../n2/components/base.js'
import AppFooter from './AppFooter.js'
import {Modal} from '../../n2/components/modal.js'


export default class PublicFrame extends Control {
    constructor(vnode) {
        super(vnode, '.flex.flex-col.w-full.min-h-screen.overflow-hidden')
    }

    getTitle() {
        return this.attrs.title || ''
    }

    renderFooter() {
        return m(AppFooter)
    }

    renderChildren() {
        return [
            m('.container.mx-auto.px-4.py-10.flex-initial.flex-grow',
                m('.w-full.lg:w-3/4.xl:w-3/5.mx-auto', ...this.vnode.children)
            ),
            this.renderFooter(),
            Modal.view()
        ]
    }
}
