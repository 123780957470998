import {
    m,
    Theme,
    Icon,
    ValueControl,
    Popup,
} from './base.js';
import {
    MenuUpIcon,
    MenuDownIcon,
} from '../icons.js';

import Options from '../data/Options.js'

const inputClasses =
    '.rounded-sm' +
    '.border.border-gray-light' +
    '.px-1' +
    '.focus:outline-none.focus:border-brand1-light'

const boxSizes = {
    'default': 'w-full',
    'small': '120px',
    'large': '240px',
}

const panelSizes = {
    'small': '240px',
    'default': '320px',
    'large': '600px',
}

export class Picker extends ValueControl {
    constructor(vnode) {
        super(vnode, Popup)
        this.open = false
    }

    getPlaceholder() {
        return this.attrs.placeholder || ''
    }
    
    getBoxDesign() {
        return this.attrs.boxDesign || 'default'
    }

    getBoxSize() {
        return this.attrs.boxSize || 'default'
    }

    getPanelSize() {
        return this.attrs.panelSize || 'default'
    }

    shouldCloseOnSelect() {
        return this.attrs.closeOnSelect || this.attrs.closeOnSelect === undefined
    }

    getOpenIconFace() {
        return this.attrs.openIconFace || this.attrs.iconFace || MenuUpIcon
    }

    getClosedIconFace() {
        return this.attrs.closedIconFace || this.attrs.iconFace || MenuDownIcon
    }

    getIconFace() {
        return this.open ? this.getOpenIconFace() : this.getClosedIconFace()
    }

    onPanelKeyUp(popup, ev) {
        this.attrs.onPanelKeyUp && this.attrs.onPanelKeyUp(popup, ev)
    }

    onPanelKeyDown(popup, ev) {
        this.attrs.onPanelKeyDown && this.attrs.onPanelKeyDown(popup, ev)
    }

    getAttributes() {
        const superAttributes = super.getAttributes()
        return Object.assign(superAttributes, {
            renderAnchor: (popup, attrs) =>
                m(
                    '.relative',
                    {
                        style: { width: boxSizes[this.getBoxSize()] }
                    },
                    m(this.getBoxDesign() === 'simple' ?
                        '.cursor-pointer' +
                        '.focus:outline-none.focus:border-brand1-light' +
                        '.select-none' : // else:
                        '.bg-white.rounded-sm' +
                        '.cursor-pointer' +
                        '.pl-1.pr-7.py-0' +
                        '.border.border-gray' +
                        '.focus:outline-none.focus:border-brand1-light',
                        Object.assign({
                            style: { width: boxSizes[this.getBoxSize()] || boxSizes.default }
                        }, attrs),
                        [
                            this.renderBoxContent(),
                            this.getBoxDesign() !== 'simple' && m('.absolute' +
                                '.w-6.h-full.top-0.right-0' +
                                '.rounded-r-sm.bg-brand1' +
                                '.flex.flex-row.items-center.place-content-center' +
                                '.pointer-events-none',
                                this.renderIcon()
                            )
                        ]
                    )
                ),
            renderContent: (popup) => this.renderPanelContent(
                (value) => {
                    this.changeValue(value)
                    if (this.shouldCloseOnSelect()) popup.close()
                },
                () => popup.close()
            ),
            onOpen: () => this.open = true,
            onClose: () => this.open = false,
            onPanelKeyUp: (popup, ev) => this.onPanelKeyUp(popup, ev),
            onPanelKeyDown: (popup, ev) => this.onPanelKeyDown(popup, ev)
        })
    }

    renderIcon() {
        const iconFace = this.getIconFace()
        return iconFace && m(Icon, { iconFace, color: (this.getBoxDesign() !== 'simple') && Theme.colors.white, size: 20 })
    }

    renderBoxLabel(label) {
        return this.getBoxDesign() === 'simple' ? m('.flex', [label, this.renderIcon()]) : String(label)
    }

    renderSelection() {
        return this.renderBoxLabel(this.getValue())
    }

    renderPlaceholder() {
        const placeholder = this.getPlaceholder()
        return this.getBoxDesign() === 'simple' ?
            this.renderBoxLabel(placeholder) :
            (placeholder ?
                m('p.overflow-ellipsis.whitespace-nowrap.overflow-hidden.text-gray-light', placeholder) :
                m('p.text-transparent', '?')
            )
    }

    renderBoxContent() {
        const value = this.getValue()
        return value === undefined || value === null ?
            this.renderPlaceholder() :
            this.renderSelection()
    }

    renderPanelContent() {
        return null
    }
}


export class Select extends Picker {
    constructor(vnode) {
        super(vnode)
    }

    getOptions() {
        return this.attrs.options
    }

    getOrder() {
        return this.attrs.order
    }
    
    getPlaceholder() {
        return this.attrs.placeholder || 'Nichts ausgewählt'
    }

    getUnselectText() {
        return this.attrs.unselect || 'Auswahl aufheben'
    }

    isSelected(option) {
        return this.value !== undefined && option && option.id === this.value
    }

    isPreSelected(option) {
        return this.preSelectionValue !== undefined && option && option.id === this.preSelectionValue
    }

    isSelectable(option) {
        return option && option.id !== undefined
    }

    preSelect(option) {
        this.preSelectionValue = option && option.id
    }

    findSelectedOption() {
        const value = this.getValue()
        return value === undefined ? undefined : Options.findOption(this.getOptions(), value)
    }

    onPanelKeyUp(popup, ev) {
        if (ev.code === 'Enter') {
            this.changeValue(this.preSelectionValue)
            popup.close()
        }
    }

    onPanelKeyDown(popup, ev) {

    }

    renderOption(target, option, selected) {
        const label = Options.getOptionLabel(option)
        if (target === 'box' && this.getBoxDesign() === 'simple') return this.renderBoxLabel(label)
        const {
            color,
            backgroundColor
        } = option
        return m('p.overflow-ellipsis.whitespace-nowrap.overflow-hidden', {
            class:
                (target === 'box' ? 'rounded-sm' : 'px-2'),
            style: (target === 'box' || !selected) && {
                color,
                backgroundColor
            }
        }, label)
    }

    renderBoxContent() {
        const selected = this.findSelectedOption()
        return selected ?
            this.renderOption('box', selected, false) :
            this.renderPlaceholder()
    }

    renderListItem(option, select, close) {
        const selectable = this.isSelectable(option)
        const isSelected = this.isSelected(option)
        const preSelected = this.isPreSelected(option)
        return m('li', {
                class: isSelected ? 'bg-brand1 text-white' : (preSelected ? 'bg-brand1-light text-white' : ''),
                onclick: () => {
                    select(option.id)
                },
                onmouseenter: () => this.preSelect(selectable ? option : undefined),
                oncreate: isSelected && ((vnode) => vnode.dom.scrollIntoView({block: "center"}))
            },
            this.renderOption('list', option, preSelected)
        )
    }

    renderPanelContent(select, close) {
        return m(
            'ul',
            [
                {
                    label: this.getUnselectText(),
                    color: Theme.colors.gray.dark
                }
            ].concat(Options.getOptionsArray(this.getOptions(), this.getOrder()))
                .map(o => this.renderListItem(o, select, close))
        )
    }
}

export class MultiSelect extends Picker {
    constructor(vnode) {
        super(vnode)
    }

    getOptions() {
        return this.attrs.options
    }

    getOrder() {
        return this.attrs.order
    }

    shouldCloseOnSelect() {
        return false
    }

    getPlaceholder() {
        return this.attrs.placeholder || '<Nichts ausgewählt>'
    }

    getUnselectText() {
        return this.attrs.unselect || 'Auswahl aufheben'
    }

    setSelected(option, selected) {
        if (option && option.id) {
            const newSelection = (this.value || []).filter(o => o !== option.id)
            if (selected) newSelection.push(option.id)
            this.changeValue(newSelection)
        }
    }

    isSelected(option) {
        return option && this.value !== undefined && this.value.findIndex(id => id === option.id) >= 0
    }

    isPreSelected(option) {
        return this.preSelectionValue !== undefined && option && option.id === this.preSelectionValue
    }

    isSelectable(option) {
        return option && option.id !== undefined
    }

    preSelect(option) {
        this.preSelectionValue = option && option.id
    }

    renderBoxContent() {
        const value = this.getValue()
        if (value && value.length > 0) {
            const label = Options.getSelectedLabel(this.getOptions(), value)
            if (label) return label
        }
        return this.renderPlaceholder()
    }

    renderListItem(option, select, close) {
        const selectable = this.isSelectable(option)
        const preSelected = this.isPreSelected(option)
        return m('li', {
            class: preSelected ? 'bg-brand1 text-white' : '',
            onclick: () => this.setSelected(option, !this.isSelected(option)),
            onmouseenter: () => this.preSelect(selectable ? option : undefined)
        }, m('.space-x-1.px-2', [
            m('input' + inputClasses, {
                id: 'check-' + option.id,
                type: 'checkbox',
                checked: this.isSelected(option),
                tabIndex: 0,
                oninput: (ev) => {
                    ev.preventDefault()
                    this.setSelected(option, Boolean(ev.target.checked))
                }
            }),
            m('label', { for: 'check-' + option.id }, Options.getOptionLabel(option))
        ]))
    }

    renderPanelContent(select, close) {
        return m(
            'ul',
            {
                onkeyup: (ev) => ev.code === 'Enter' && (this.showPicker = false),
            },
            [
                m('li', {
                    class: 'hover:bg-brand1 hover:text-white',
                    onclick: () => select([]),
                    onmouseenter: () => this.preSelect(undefined)
                }, m('.px-2', this.getUnselectText())),
                ...Options.getOptionsArray(this.getOptions(), this.getOrder())
                    .map(o => this.renderListItem(o, select, close))
            ]
        )
    }
}
