
class IconFace {
    constructor(props) {
        this.id = IconFace.idCounter++
        this.svg = props.svg.replace('PREAMBLE', 'xmlns="http://www.w3.org/2000/svg" fill="FILL" fill-opacity="OPACITY"');
        this.size = props.size || 24
    }

    getIconSrc(color, opacity) {
        const svg = this.svg
            .replace('FILL', color)
            .replace('OPACITY', opacity);
        return 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svg)));
    }

    getIconImg(size, color, opacityParam) {
        const opacity = opacityParam === undefined ? 1.0 : opacityParam;
        const img = document.createElement('img');
        img.src = this.getIconSrc(color, opacity);
        img.width = img.height = size;
        return img;
    }

    getIconCanvas(size, color, opacityParam) {
        const opacity = opacityParam === undefined ? 1.0 : opacityParam;
        const key = [this.id, size, color, opacity].join('-');
        let canvas = IconFace.canvasCache[key];
        if (canvas) {return canvas}
        canvas = IconFace.canvasCache[key] = document.createElement('canvas');
        canvas.width = canvas.height = size;
        const img = this.getIconImg(size, color, opacity);
        const draw = () => {
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, size, size);
            const scale = size / this.size;
            context.scale(scale, scale);
            context.drawImage(img, 0, 0);
        };
        if (img.complete) {draw()} else {img.onload = draw}
        return canvas;
    }
}

IconFace.canvasCache = {}
IconFace.idCounter = 0

export default IconFace
