import { Control, m } from '../../n2/components/base.js'
import Card from '../../shared/components/Card.js'
import {CalendarIcon} from '../../n2/icons.js'

export default class SlotCards extends Control {
    constructor(vnode) {
        super(vnode, '.flex.flex-wrap')
        this.date = new Date()
    }

    getAttributes() {
        const attrs = super.getAttributes()
    }

    formatDate(date) {
        const newDate = new Date(date)
        return ("0" + newDate.getHours()).slice(-2) + '.' + ("0" + newDate.getMinutes()).slice(-2);
    }

    getStatusClasses(slot) {
        let classString = '';
        const startDate = new Date(slot.start);
        const endDate = new Date(slot.end);
        if (this.date >= startDate && this.date < endDate) {
            classString += '.bg-brand1 ';
        } else if (slot.exceeded !== undefined && slot.exceeded) {
            classString += '.bg-danger '
        } else {
            classString += '.bg-success '
        }
        return classString;
    }

    renderCard(slot) {
        let subtitle = this.attrs.showStatus ?
            "Buchungen: " + slot.booked + " - Freie Plätze: " + (slot.capacity - slot.booked) + " - Kapazität: " + slot.capacity :
            "Freie Plätze: " + (slot.capacity - slot.booked)
        let cardAction = undefined;
        if (this.attrs.isBooking && slot.allowed && this.attrs.cardAction !== undefined) {
            cardAction = () => this.attrs.cardAction(slot)
        }
        let backgroundColor = '.bg-white'
        let textColor = '.text-black'
        let comment = ''
        if (this.attrs.showStatus) {
            backgroundColor = this.getStatusClasses(slot)
            textColor = '.text-white'
        } else if (this.attrs.isBooking && !slot.allowed) {
            backgroundColor = '.bg-gray-light'
            textColor = '.text-gray-dark'
            comment = ' (andere Buchung am gleichen Tag)'
        }

        return m(Card, {
            key: slot.start.toUTCString(),
            title: this.formatDate(slot.start) + ' - ' + this.formatDate(slot.end) + ' Uhr' + comment,
            subtitle: subtitle,
            icon: CalendarIcon,
            textColor: textColor,
            backgroundColor: backgroundColor,
            borderRadius: '.rounded-lg',
            cardAction: cardAction,
            size: 'lg',
            class: this.attrs.showStatus ? this.getStatusClasses(slot) : ''
        });
    }

    renderChildren() {
        return this.attrs.slots.map(slot => this.renderCard(slot))
    }
}
