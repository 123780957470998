import {
    Button,
    IconButton,
    Icon,
    Control,
    m
} from '../../n2/components/base.js'

import {
    PencilIcon,
    TrashIcon,
    PdfIcon,
} from '../../n2/icons.js'

import Theme from '../../theme.js'

const size = {
    'sm' : 'md:px-2 lg:w-1/4 md:w-1/2 w-full',
    'md' : 'md:px-2 md:w-1/2 w-full',
    'lg' : 'w-full',
}

export default class Card extends Control {
    constructor(vnode) {
        super(vnode, 'div.my-2')
    }

    getAttributes() {
        const attrs = super.getAttributes()
        return Object.assign(attrs, {
            class: (attrs.class ? attrs.class + ' ' : '') + this.getSize()
        })
    }

    getTitle() {
        return this.attrs.title
    }

    getSubtitle() {
        return this.attrs.subtitle
    }

    getCardAction() {
        return this.attrs.cardAction
    }

    getBackgroundColor() {
        return this.attrs.backgroundColor
    }

    getBorderRadius() {
        return this.attrs.borderRadius
    }

    getStrikedText() {
        return this.attrs.strikedText
    }

    getTextColor() {
        return this.attrs.textColor
    }

    getDelete() {
        return this.attrs.delete
    }

    getEdit() {
        return this.attrs.edit
    }

    getDownload() {
        return this.attrs.download
    }

    getSize() {
        return size[this.attrs.size || 'md'] || ''
    }

    getIcon() {
        return this.attrs.icon
    }

    getButton() {
        return this.attrs.button
    }

    getButtonAction() {
        return this.attrs.buttonAction
    }

    getButtonTitle() {
        return this.attrs.buttontitle
    }

    getText() {
        return this.attrs.text
    }

    getFullText() {
        return this.attrs.full_text || false
    }

    triggerCardAction() {
        const cardAction = this.getCardAction();
        if (typeof cardAction === 'string') {
            m.route.set(cardAction)
        } else if(typeof cardAction === 'function') {
            cardAction()
        }
    }

    triggerDeleteAction() {
        const deleteAction = this.getDelete();
        if (typeof deleteAction === 'string') {
            m.route.set(deleteAction)
        } else if(typeof deleteAction === 'function') {
            deleteAction()
        }
    }

    renderDelete() {
        return m('div.control-button.font-bold',
                    m(IconButton, {
                        title: 'Löschen',
                        iconFace: TrashIcon,
                        danger: true,
                        size: '40',
                        onclick: (e) => {
                            e.preventDefault()
                            this.triggerDeleteAction()
                        },
                    }),
                )
    }

    renderEdit() {
        return m('div.control-button.font-bold',
                    m(IconButton, {
                        title: 'Bearbeiten',
                        iconFace: PencilIcon,
                        size: '40',
                        onclick: (e) => {
                            e.preventDefault()
                            m.route.set(this.getEdit())
                        },
                    }),
                )
    }

    renderDownload() {
        return m('a.control-button.font-bold', {href: this.getDownload()},
                    m(IconButton, {
                        title: 'Download',
                        iconFace: PdfIcon,
                        size: '40',
                    }),
                )
    }

    renderIcon() {
        let color = Theme.colors.brand1.DEFAULT
        const backgroundColor = this.getBackgroundColor();
        if (backgroundColor !== undefined) {
            if (backgroundColor === '.bg-gray-light') {
                color = Theme.colors.gray.dark
            } else if(backgroundColor !== '.bg-white') {
                color = Theme.colors.white
            }
        }

       return m(Icon, {
            title: this.getTitle(),
            iconFace: this.getIcon(),
            size: '40',
            color: color,
        })
    }

    triggerButtonAction() {
        const buttonAction = this.getButtonAction();
        if (typeof buttonAction === 'string') {
            m.route.set(buttonAction)
        } else if (typeof buttonAction === 'function') {
            buttonAction()
        }
    }

    renderButton() {
        return m('.flex.justify-start.my-4.control-button',
            m(Button, {
                class: 'rounded-sm font-bold py-1 px-2',
                onclick: (e) => {
                    e.preventDefault()
                    this.triggerButtonAction()
                }
            }, this.getButtonTitle()),
        )
    }

    renderText() {
        return m('p.text-gray', this.getText())
    }

    renderChildren() {
        const textColor = this.getTextColor() !== undefined ? this.getTextColor() : '.text-gray-darkest';
        const backgroundColor = this.getBackgroundColor() !== undefined ? this.getBackgroundColor() : '.bg-white';
        const borderRadius = this.getBorderRadius() !== undefined ? this.getBorderRadius() : '';
        return [m('.flex.md:flex-nowrap.flex-wrap.md:h-20.h-auto' + backgroundColor + textColor + borderRadius + '.shadow.items-center.border.border-gray-lightest.p-3.hover:border-brand1.cursor-pointer.justify-between.z-0',
            {
                onclick: (e) => {
                    if (!e.target.matches('.control-button *')) {
                        this.triggerCardAction()
                    }
                }
            },
            this.getIcon() && m('div.block.pr-2', this.renderIcon()),
            m('div.block' + (this.getStrikedText() && '.line-through') + ((this.getDelete() || this.getEdit()) ? '.w-3/4' : '.w-full'),
                !this.getFullText() ? (this.getTitle() && m('p' + textColor + '.font-bold.truncate', {title: this.getTitle()}, this.getTitle())) : (this.getTitle() && m('p' + textColor + '.font-bold.text-wrap', {title: this.getTitle()}, this.getTitle())),
                this.getSubtitle() && m('p.truncate', {title: this.getSubtitle()}, this.getSubtitle()),
            ),
            m('div.flex',
                this.getDownload() && this.renderDownload(),
                this.getEdit() && this.renderEdit(),
                this.getButton() && this.renderButton(),
                this.getText() && this.renderText(),
                this.getDelete() && this.renderDelete(),
            ),
        )]
    }
}
