import {
    Control,
    Icon,
    Button,
    m
} from '../../n2/components/base.js'

import {
    PlusIcon,
    MinusIcon,
} from '../../n2/icons.js'

import Theme from '../../theme.js'

export class MainHeadline extends Control {
    constructor(vnode) {
        super(vnode, 'div')
    }

    getTitle() {
        return this.attrs.title
    }

    renderChildren() {
        return this.getTitle() && m('h1.flex.mb-6.font-bold.text-brand1.text-2xl.sm:text-3xl.justify-center', this.getTitle())
    }
}

export class BackButton extends Control {
    constructor(vnode) {
        super(vnode, 'div')
    }

    getTitle() {
        return this.attrs.title || 'Zurück'
    }

    getLink() {
        return this.attrs.backlink
    }

    renderChildren() {
        return m('.flex',
            m(Button, {
                kind: 'default',
                onclick: () => m.route.set(this.getLink())
            }, this.getTitle()),
        )
    }
}

export class ExpandedCard extends Control {
    constructor(vnode) {
        super(vnode, 'div.w-full')
        this.expanded = Boolean(vnode.attrs.expanded)
    }

    getTitle() {
        return this.attrs.title || 'Statistiken'
    }

    renderButton() {
        return m(
                '.bg-white' +
                '.hover:bg-gray-lightest.cursor-pointer' +
                '.border.border-brand1' +
                '.h-7.w-7' +
                '.flex.flex-row.place-items-center.justify-center',
                m(Icon, {
                    iconFace: this.expanded ? MinusIcon : PlusIcon,
                    color: Theme.colors.brand1.DEFAULT,
                    size: 18
                })
            )
    }

    renderChildren() {
        return m('div',
                m('div.flex.flex-col.p-3.bg-gray-darkest.my-2',
                    m('div.w-full.flex.justify-between.items-center.cursor-pointer',
                        {onclick: () => this.expanded = !this.expanded},
                        m('p.text-white.font-bold', this.getTitle()),
                        this.renderButton(),
                    ),
                ),
                (this.expanded ? this.vnode.children : '')
            )
    }
}
