import {
    Button,
    m
} from '../../n2/components/base.js'

import {MainHeadline} from '../../shared/components/MainComponents.js'

import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

import Timer from '../../shared/components/Timer.js'

export default class SelfTestPage extends AppPage {
    getSelfTest() {
        this.attrs.selftest || false
    }

    renderChildren() {
        if (this.getSelfTest()) {
            return  [
                m(MainHeadline, {title: 'Selbsttest'}),
                m('hr.border-brand1.my-2'),
                m('h2.text-xl.font-bold', '1. Bitte wählen Sie ein Testzentrum:'),
                m('h2.text-xl.font-bold', '2. Timer starten:'),
                m('h2.text-xl.font-bold', '3. Abwarten:'),
                m(Timer),
                m('h2.text-xl.font-bold', '4. Testergebnis auswählen:'),
                m('h2.text-xl.font-bold', '5. QR-Code:'),
                m('h2.text-xl.font-bold', '6. Weiterleitung zum Zertifikat:'),
            ]
        } else {
            return  [
                m(MainHeadline, {title: 'Selbsttest'}),
                m('hr.border-brand1.my-2'),
                m('h2.text-xl.font-bold', 'Diese Funktion steht momentan nicht bereit.')
            ]
        }
    }
}
