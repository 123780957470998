import {m, Input, Button, Control} from '../../n2/components/base.js'
import {EmbeddedForm} from '../../n2/components/form.js'
import FormModel from '../../n2/data/FormModel.js'

export class UserPage extends Control {
    constructor(vnode) {
        super(vnode)
        this.success = false
    }

    getUser() {
        return this.attrs.user
    }

    getDataSource() {
        const user = this.getUser()
        return user && user.getDataSource()
    }

    getUserEntity() {
        const user = this.getUser()
        return user && user.getEntity()
    }

    getUserId() {
        const entity = this.getUserEntity()
        return entity && entity.id
    }

    getNext() {
        return this.attrs.next
    }

    getSuccessTitle() {
        return this.attrs.successTitle || 'Die Änderung wurde erfolgreich durchgeführt.'
    }

    getSuccessBody() {
        return this.attrs.successBody
    }

    getSuccessButton() {
        return this.attrs.successButton
    }

    getSuccessButtonLink() {
        return this.attrs.successButtonLink
    }

    getSuccessSeconds() {
        return 2
    }

    next() {
        this.success = true
        const next = this.getNext()
        next && setTimeout(() => m.route.set(next), this.getSuccessSeconds() * 1000)
    }

    renderContent() {

    }

    renderChildren() {
        return this.success ?
            [
                m('p.text-brand1.text-center.font-bold', this.getSuccessTitle()),
                this.getSuccessBody() && m('p.text-brand1.text-center', this.getSuccessBody()),
                this.getSuccessButton() && m('.text-center.my-4', m('a.rounded-sm.font-bold.focus:outline-none.bg-brand1.border.border-brand1.disabled:bg-brand1-light.text-white.py-1.px-2', {href: this.getSuccessButtonLink(), title: this.getSuccessButton()}, this.getSuccessButton()))
            ]
            :
            this.renderContent()
    }
}

export class UserFormPage extends UserPage {
    constructor(vnode) {
        super(vnode)
        this.formModel = new FormModel(this.getDataSource(), this.getOperation(), this.getUserEntity())
        this.configureModel(this.formModel)
    }

    getUserEntity() {
        return this.getOperation() === 'create' ?  {} : super.getUserEntity()
    }

    getOperation() {
        return this.attrs.operation || 'update'
    }

    getSaveLabel() {
        return this.attrs.saveLabel || 'Speichern'
    }

    configureModel(model) {

    }

    renderFormContent(form) {

    }

    renderContent() {
        return m(EmbeddedForm, {
            model: this.formModel,
            type: 'default',
            renderContent: (form) => this.renderFormContent(form),
            saveLabel: this.getSaveLabel(),
            onSave: () => this.next()
        })
    }
}

export class ChangePasswordPage extends UserFormPage {

    constructor(vnode) {
        super(vnode)
    }

    getMode() {
        return this.attrs.mode || 'password'
    }

    async send(entity) {
        const dataSource = this.getDataSource()
        try {
            if (this.getMode() === 'password') {
                await dataSource.callEntityCommand(this.getUserId(), 'changePassword', entity.originalPassword, entity.password)
            } else {
                await dataSource.callEntityCommand(m.route.param('id'), 'resetPassword', m.route.param('code'), entity.password)
            }
        } catch (ex) {
            if (ex.message) {
                if (ex.code === 405) {
                    entity[(this.getMode() === 'password' ? 'originalPassword' : '*') + ':problems'] = [ex.message]
                } else if (ex.code === 400) {
                    entity['password:problems'] = [ex.message]
                }
                ex.entity = entity
            }
            throw ex
        }
        return entity
    }

    configureModel(model) {
        model.setOnSave((e) => this.send(e))
        model.onPrepareOutboundEntity((e) => delete e.passwordRepetition)
        model.onPrepareInboundEntity((e) => e.passwordRepetition = undefined)
        model.mandatory((this.getMode() === 'password' ? 'originalPassword,' : '') + 'password,passwordRepetition')
        model.sendOnly('originalPassword,passwordRepetition')
        model.addConstraint(
            'password',
            (e) => e.password && e.password.length > 5 ? true : 'Das Passwort ist zu kurz'
        )
        model.addConstraint(
            'passwordRepetition',
            (e) => e.password === e.passwordRepetition ? true : 'Passwort und Passwortwiederholung unterscheiden sich'
        )
    }

    renderFormContent(form) {
        return form.group([
            (this.getMode() === 'password') &&
            form.string('originalPassword', 'Bisheriges Passwort', {type: 'password'}),
            form.string('password', 'Neues Passwort', {type: 'password'}),
            form.string('passwordRepetition', 'Neues Passwort (Wiederholung)', {type: 'password'})
        ])
    }

    getSuccessTitle() {
        return this.hasIdAndToken() ?
            'Sie haben Ihr Passwort erfolgreich geändert.' :
            'Ein Link zum Zurücksetzen des Passwortes wurde an Ihre E-Mail-Adresse geschickt.'
    }

    getSaveLabel() {
        return 'Passwort ändern'
    }

    async requestPasswordReset() {
        await this.getDataSource().callCollectionCommand('lostPassword', this.email)
        this.next()
    }

    hasIdAndToken() {
        return m.route.param('id') && m.route.param('code')
    }

    renderContent() {
        if (this.getMode() === 'password' || this.hasIdAndToken()) return super.renderContent()
        return [
            m('span', 'Bitte geben Sie zum Zurücksetzen Ihres Passwortes die registrierte E-Mail-Adresse an:'),
            m(Input, {
                class: 'my-4 py-2 w-full block',
                type: 'email',
                required: true,
                autofocus: true,
                onChange: (v) => this.email = v
            }),
            m(Button, {
                class: 'mb-2',
                onclick: () => this.requestPasswordReset()
            }, [
                'Passwortzurücksetzung anfordern'
            ])
        ]
    }
}

export class RegistrationPage extends UserFormPage {
    getOperation() {
        return 'create'
    }

    configureModel(model) {
        model.onPrepareOutboundEntity((e) => delete e.passwordRepetition)
        model.onPrepareInboundEntity((e) => e.passwordRepetition = e.password)
        model.mandatory('email,password,passwordRepetition,priv')
        model.sendOnly('password,passwordRepetition')
        model.addConstraint(
            'email',
            (e) => e.email && e.email.length > 5 ? true : 'Keine gültige E-Mail-Adresse'
        )
        model.addConstraint(
            'password',
            (e) => {
                    if ((/[A-Z]/.test(e.password)) && e.password && e.password.length > 5) {
                        return true
                    } else {
                        if (!(/[A-Z]/.test(e.password))) {
                            return 'Das Passwort enhält keinen Großbuchstaben'
                        }

                        if (e.password && e.password.length > 5) {
                            return 'Das Passwort ist zu kurz'
                        }
                    }
                }
        )
        model.addConstraint(
            'passwordRepetition',
            (e) => e.password === e.passwordRepetition ? true : 'Passwort und Passwortwiederholung unterscheiden sich'
        )
        model.addConstraint(
            'priv',
            (e) => e.priv === true ? true : 'Checkbox ist nicht angeklickt'
        )
    }

    renderFormContent(form) {
        return form.group([
            form.string('email', 'E-Mail-Adresse', {type: 'email'}),
            form.string('password', 'Passwort', {type: 'password'}),
            form.string('passwordRepetition', 'Passwortwiederholung', {type: 'password'}),
            form.option('priv', m.trust('<span class="md:text-base text-sm">Ich stimme der <a class="text-brand1" href="/#/registration-privacy" title="Datenschutzerklärung" target="_blank">Datenschutzerklärung</a> zu.</span>'))
        ])
    }

    next() {
        this.success = true
        const next = this.getNext()
        //next && setTimeout(() => m.route.set(next), this.getSuccessSeconds() * 1000)
    }

    getSuccessTitle() {
        return 'Vielen Dank für Ihre Registrierung.'
    }

    getSuccessBody() {
        return 'Sie haben einen Aktivierungscode per E-Mail bekommen.' +
            ' Bitte überprüfen Sie auch Ihren Spam-Ordner.'
    }

    getSuccessButton() {
        return 'Ok'
    }

    getSuccessButtonLink() {
        return '/'
    }

    getSuccessSeconds() {
        return 8
    }

    getSaveLabel() {
        return 'Registrieren'
    }
}

export class DeleteAccountPage extends UserFormPage {
    constructor(vnode) {
        super(vnode)
    }

    async next() {
        this.success = true
        const next = this.getNext()
        next && setTimeout(() => {
            location.reload()
        }, this.getSuccessSeconds() * 1000)
    }

    async send(entity) {
        const dataSource = this.getDataSource()
        try {
            await dataSource.callEntityCommand(this.getUserId(), 'logout')
            await dataSource.callEntityCommand(entity.id, 'delete', entity.password)
        } catch (ex) {
            throw ex
        }
    }

    configureModel(model) {
        model.setOnSave((e) => this.send(e))
        model.addConstraint(
            'password',
            (e) => e.password && e.password.length > 5 ? true : 'Das Passwort ist zu kurz'
        )
    }

    getSaveLabel() {
        return 'Konto löschen'
    }

    renderFormContent(form) {
        return [
            m('p.mb-3.text-center', 'Bitte geben Sie Ihr aktuelles Passwort an, um den Löschvorgang zu bestätigen.'),
            form.group([
                form.string('password', 'Aktuelles Passwort', {type: 'password'}),
            ])
        ]
    }
}

export class LoginPage extends UserPage {
    constructor(vnode) {
        super(vnode)
        this.email = undefined
        this.password = undefined
        this.emailToken = m.route.param('code')
        this.requestTokenState = 'Sie haben keinen Code erhalten? Dann können Sie hier einfach einen neuen anfordern:'
    }

    getRegisterRoute() {
        return this.attrs.registerRoute
    }

    getResetPasswordRoute() {
        return this.attrs.resetPasswordRoute
    }

    getSuccessTitle() {
        return this.getUser().isLoggedIn() ? 'Willkommen zurück!' : 'Sie haben sich erfolgreich abgemeldet.'
    }

    async login() {
        const user = this.getUser()
        await user.login(this.email, this.password, this.emailToken)
        if (user.isLoggedIn()) this.next()
    }

    onKeyUp(ev) {
        if (ev.code === 'Enter') this.login()
    }

    async logout() {
        const user = this.getUser()
        await user.logout()
        if (!user.isLoggedIn()) this.next()
    }

    async requestToken() {
        this.requestTokenState = 'Fordere neuen E-Mail Verifikations-Code an...'
        try {
            await this.getUser().requestToken(this.email, this.password)
            this.requestTokenState = 'Es wurde ein neuer Verifikations-Code an Ihre E-Mail-Adresse versendet.'
        } catch (ex) {
            this.requestTokenState = 'Beim Versenden eines neuen Verifikations-Codes ist ein Problem aufgetreten. Versuchen Sie es bitte später noch einmal.'
        }
    }

    emailTokenIsValid() {
        return this.emailToken && this.emailToken.length === 4
    }

    canSend() {
        return (
            this.email && this.email.length > 5 &&
            this.password && this.password.length > 5 &&
            (
                this.getUser().getTokenState() !== 'required' ||
                (this.getUser().getTokenState() === 'required' && this.emailTokenIsValid())
            )
        )
    }

    renderForms() {
        const user = this.getUser()
        const resetPasswordRoute = this.getResetPasswordRoute()
        if (user.isLoggedIn()) {
            return [
                m(Button, {
                    key: 'logout',
                    class: 'mt-4',
                    disabled: !this.getUser().isLoggedIn(),
                    onclick: () => this.logout()
                }, [
                    'Abmelden'
                ]),
            ]
        } else if (user.getTokenState() !== 'provided') {
            if (user.getTokenState() === 'expired') {
                return [
                    m('span', {key: 'expired'}, 'Ihr Verifikations-Code ist leider abgelaufen.'),
                    m(Button, {
                        key: 'requestNewToken1',
                        class: 'mt-4',
                        onclick: () => this.getUser().requestToken(this.email, this.password)
                    }, [
                        'Neuen Code anfordern'
                    ])
                ]
            } else {
                return [
                    m('span', {key: 'wrong'}, user.getTokenState() === 'wrong' ?
                        'Der E-Mail Verifikations-Code ist leider nicht korrekt.' :
                        ''
                    ),
                    m(Input, {
                        key: 'code',
                        class: 'mb-4 py-2 w-full block',
                        type: 'text',
                        placeholder: 'E-Mail Verifikations-Code',
                        required: true,
                        autofocus: true,
                        onChange: (v) => this.emailToken = v
                    }),
                    m(Button, {
                        key: 'verifyCode',
                        class: 'my-4 hover:bg-brand1-dark w-full block',
                        disabled: !this.canSend(),
                        onclick: () => this.login()
                    }, [
                        'Verifizieren und anmelden'
                    ]),
                    m('.text-center.mt-6', { key: 'spam' }, this.requestTokenState),
                    m(Button, {
                        key: 'requestNewToken2',
                        class: 'mt-2 mb-4 mx-10',
                        onclick: () => this.requestToken()
                    }, [
                        'Neuen Code anfordern'
                    ])
                ]
            }
        } else return [
            m(Input, {
                key: 'email',
                class: 'mb-4 py-2 w-full block',
                type: 'email',
                required: true,
                autofocus: true,
                onChange: (v) => this.email = v,
                onkeyup: (ev) => this.onKeyUp(ev),
            }),
            m(Input, {
                key: 'password',
                type: 'password',
                class: 'py-2 w-full block mb-3',
                required: true,
                onChange: (v) => this.password = v,
                onkeyup: (ev) => this.onKeyUp(ev)
            }),
            resetPasswordRoute ? m(m.route.Link, {
                key: 'reset-password',
                class: 'cursor-pointer mt-3 text-brand1 hover:text-brand1-darkest hover:underline',
                href: resetPasswordRoute
            }, 'Passwort vergessen?') : m('div', { key: 'reset-password' }),
            m(Button, {
                key: 'login',
                class: 'my-4 hover:bg-brand1-dark w-full block',
                disabled: !this.canSend(),
                onclick: () => this.login()
            }, [
                'Anmelden'
            ])
        ]
    }

    renderNote() {
        return this.attrs.renderLoginNote && this.attrs.renderLoginNote()
    }

    renderContent() {
        const lastMessage = this.getUser().getLastMessage()
        const lastCode = this.getUser().getLastCode()
        const registerRoute = this.getRegisterRoute()
        const user = this.getUser()
        return [
            !user.isLoggedIn() && this.renderNote(),
            m('.flex-col.flex', [
                lastMessage && lastCode !== 404 && m('h2.text-center.text-danger.font-bold.mb-4', lastMessage),
                this.renderForms(),
                m('.text-center', [
                    registerRoute && m(m.route.Link, {
                        class: 'cursor-pointer mt-3 text-center text-brand1 hover:text-brand1-darkest hover:underline',
                        href: registerRoute
                    }, 'Noch kein Konto? Jetzt registrieren.'),

                ])
            ])
        ]
    }
}
