import App from '../../app/state/App.js';
import {
    m,
} from '../../n2/components/base.js';
import { Modal } from '../../n2/components/modal.js'
import Entity from '../../n2/data/Entity.js'

export default function cancelEntity(dataSource, entityId, entityName, confirmation, cancel) {
    async function reconfirmDelete(entity) {
        if(await Modal.confirm(
            [
                'Warnungen:',
                m('br'),
                m('ul', Entity.getGeneralWarnings(entity).map(w => m('li', w)))
            ],
            { title: 'Achtung', acceptKind: 'danger' }
        ) !== 'accept') {
            return false
        } else {
            return await accept(entity.id, true)
        }
    }
    async function accept(id, ignoreWarnings) {
        try {
            await dataSource.callEntityCommand(id, 'cancel')
            App.bookings.reloadAll()
            return true
        } catch ({code, message, entity}) {
            if (code === 403 && entity) {
                if (Entity.hasGeneralProblems(entity)) {
                    Modal.alert([
                        'Probleme:',
                        m('br'),
                        m('ul', Entity.getGeneralProblems(entity).map(w => m('li', w)))
                    ])
                } else if (Entity.hasGeneralWarnings()) {
                    return await reconfirmDelete(entity)
                } else {
                    Modal.alert(entityName + ' konnte nicht abgesagt werden: ' + message)
                }
            } else if (code === 400) {
                Modal.alert(message)
            } else if (code === 404) {
                Modal.alert('Der Termin wurde bereits gelöscht.')
            } else {
                Modal.alert('Problem bei der Übertragung. Bitte versuchen Sie es später erneut.')
            }
        }
    }
    return (confirmation || confirmation === undefined) ?
        new Modal({
            title: "Den " + entityName + (cancel ? ' absagen?' : ' löschen?'),
            accept: {
                kind: 'danger',
                label: (cancel ? 'Absagen' : 'Löschen'),
                action: () => accept(entityId)
            },
            cancel: {
                label: 'Abbrechen'
            },
            renderContent: () => m('p', 'Soll der ' + entityName + ' wirklich ' + (cancel ? 'abgesagt' : 'gelöscht') + ' werden?')
        }).show() :
        accept(entityId)
}
