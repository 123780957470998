import {
    Button,
    m
} from '../../n2/components/base.js'

import Card from '../../shared/components/Card.js'
import {MainHeadline} from '../../shared/components/MainComponents.js'
import AppPage from '../components/AppPage.js'
import App from '../../app/state/App.js'

import {
    UsersIcon
} from '../../n2/icons.js'

import {deleteEntity} from '../../n2/components/crud.js'

const iconCards = [
    {
        title: 'Testperson hinzufügen',
        icon: UsersIcon,
        cardAction: '/create-proband',
    },
]

export default class ProbandsPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    confirmDelete(proband) {
        return deleteEntity(App.probands, proband.id, this.getEntityName(proband), true)
    }

    getEntityName(entity) {
        return this.attrs.entityName ? this.attrs.entityName(entity) : (entity.name || this.getSingularName())
    }

    renderIconCards() {
        return iconCards.map(a =>
                m(Card,
                    {
                        title: a.title,
                        subtitle: a.subtitle,
                        icon: a.icon,
                        cardAction: a.cardAction,
                        size: a.size,
                    }
                ),
            )
    }

    renderChildren() {
        return [
            m(MainHeadline, {title: 'Übersicht Testpersonen'}),
            m('.flex.justify-end', [
                this.renderIconCards(),
            ]),
            m('hr.border-brand1.my-2'),
            m('.flex.flex-wrap',
                ...App.probands.all.array.map(p =>
                    m(Card,
                        {
                            title: p.name,
                            id: p.id,
                            cardAction: '/probands/' + p.id,
                            delete: () => this.confirmDelete(p),
                            edit: '/probands/' + p.id + '/edit',
                        }
                    ),
                ),
            ),
        ]
    }
}
