
import User from './User.js'
import DataSource from '../../n2/data/DataSource.js'

const baseUrl = '/'
const dataSource = (collectionName, fetchAll, fetchImmediately) => {
    const ds = new DataSource(baseUrl + 'data/' + collectionName, fetchAll)
    if (fetchImmediately) ds.reloadAll()
    return ds
}

const users = dataSource('accounts')
const user = new User(users)

const loggedInSources = {
    probands: dataSource('probands', true),
    centers: dataSource('centers', true),
    bookings: dataSource('bookings', true),
    tests: dataSource('tests', true),
}

user.onAvailabilityChange.register((a, available) => {
    Object.values(loggedInSources).map(ds => available ? ds.reloadAll() : ds.clearAll())
    if (!available) user.reset()
})

async function ensureLoaded() {
    Object.values(loggedInSources).map(ds => available ? ds.reloadAll() : ds.clearAll())
}

const App = {
    users,
    user,
    ...loggedInSources,
    baseUrl,
    ensureLoaded
}

export default App
