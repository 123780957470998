import {Button, m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'

import {
    MainHeadline,
} from '../../shared/components/MainComponents.js'
import Card from '../../shared/components/Card.js'
import {
    FactoryIcon,
    CalendarIcon,
} from '../../n2/icons.js'
import SlotCards from '../../shared/components/SlotCards.js'
import {
    toDateString,
    toTimeString,
    dateEqual
} from '../../n2/dateTime.js'

export default class ProbandBookingPage extends AppPage {
    constructor(vnode) {
        super(vnode)
        this.center = undefined
        this.slots = []
        this.slot = undefined
        this.date = undefined
        this.proband = this.getEntityId() !== 0
            ? this.getEntityId()
            : undefined
    }

    getOperation() {
        return this.attrs.operation || 'create'
    }

    getEntityId() {
        return m.route.param('proband') || 0
    }

    getProband() {
        return App.probands.all.byId[this.proband];
    }

    getName() {
        const proband = this.getProband()
        return proband ? proband.name : 'unbekannt'
    }

    setCenter(center) {
        this.center = center
        this.loadBookableSlots()
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    setSlot(slot) {
        this.slot = slot
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    setProband(id) {
        this.proband = id
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    setDate(date) {
        this.date = date
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    async loadBookableSlots(date) {
        const probandId = this.proband
        if (probandId !== undefined && this.center) {
            this.slots = await App.centers.callEntityCommand(this.center.id, 'getBookableSlots', probandId) || []
        }
    }

    renderCenters() {
        return App.centers.all.array.map(a =>
            m(Card,
                {
                    title: a.name,
                    subtitle: '',
                    icon: FactoryIcon,
                    cardAction: () => this.setCenter(a),
                    size: 'lg',
                }
            ),
        )
    }

    renderSlots() {
        return m(SlotCards, {
            slots: this.slots.filter(date => {
                return dateEqual(date.start, this.date)
            }),
            isBooking: true,
            cardAction: (slot) => this.setSlot(slot),
        })
    }

    renderProbands() {

        return App.probands.all.array.length > 0 ? App.probands.all.array.map(p =>
            m(Card,
                {
                    title: p.name,
                    id: p.id,
                    cardAction: (id) => this.setProband(p.id),
                    size: 'lg'
                }
            ),
        ) : m('span.text-center.text-brand1', 'Um Termine buchen zu können, müssen Sie zunächst mindestens eine Testperson anlegen.')
    }

    renderDates() {
        let uniqueDates = [];
        this.slots.map(function (slot) {
            const startDate = new Date(slot.start).setHours(0, 0, 0, 0)
            if (!uniqueDates.includes(startDate)) {
                uniqueDates.push(startDate)
            }
            return false;
        })

        return uniqueDates.map(date => {
            const newDate = new Date(date)
            return m(Card,
                {
                    title: toDateString(newDate),
                    subtitle: '',
                    icon: CalendarIcon,
                    cardAction: () => this.setDate(newDate),
                    size: 'lg',
                }
            )
        })
    }

    async confirmBooking() {
        try{
            await App.bookings.create({
                proband: this.proband,
                center: this.center.id,
                when: new Date((this.slot.start.getTime() + this.slot.end.getTime()) / 2)
            })
        } catch (ex) {
            this.failed = true
        }
        await this.loadBookableSlots()
        this.booked = true
        setTimeout(() => {
            m.route.set('/bookings')
        }, 3000)
    }

    cancelBooking() {
        m.route.set('/probands/' + this.proband + '/assignments')
    }

    renderBooking() {
        return this.booked ? this.renderMessage() :
            m('div', [
            m(Card,
                {
                    title:
                        'Am ' + toDateString(this.slot.start) +
                        ' zwischen ' + toTimeString(this.slot.start) +
                        ' und ' + toTimeString(this.slot.end) + ' Uhr',
                    subtitle: this.center.name,
                    cardAction: undefined,
                    size: 'lg',
                }),
            m('.flex.justify-center', [
                m(Button, {
                    kind: 'danger',
                    class: 'mr-2',
                    onclick: () => this.cancelBooking()
                }, 'Abbrechen'),
                m(Button, {
                    kind: 'primary',
                    onclick: () => this.confirmBooking()
                }, 'Buchen')
            ])
        ])
    }

    renderReturnButton() {
        let clickAction = () => {}
        if (this.date === undefined) {
            clickAction = () => this.setCenter(undefined)
        } else if (this.slot === undefined) {
            clickAction = () => this.setDate(undefined)
        }
        return m(Button, {
            class: 'h-9',
            kind: 'primary',
            onclick: clickAction
        }, 'Zurück')
    }

    renderMessage() {
        return this.failed ? 'Bei der Buchung ist ein Problem aufgetreten. Versuchen Sie es ggf. später erneut.' :
            'Sie haben erfolgreich einen Testtermin für den ' + toDateString(this.slot.start) +
            ' zwischen ' + toTimeString(this.slot.start) +
            ' und ' + toTimeString(this.slot.end) + ' Uhr gebucht.'
    }

    renderForm() {
        return m('div', [
            m('.flex.flex-1.flex-col.sm:flex-row', [
                this.proband !== undefined && this.slot === undefined &&
                m('.flex.flex-col.bg-brand1.py-2.px-4.rounded-md', [
                    this.proband !== undefined && m('p.text-white', [
                        m('span', {class: 'font-bold'}, 'Proband: ')
                    ], this.getName()),
                    this.center !== undefined && m('p.mt-2.text-white', [
                        m('span', {class: 'font-bold'}, 'Testzentrum: ')
                    ], this.center.name),
                    this.date !== undefined && m('p.mt-2.text-white', [
                        m('span', {class: 'font-bold'}, 'Datum: ')
                    ], toDateString(this.date)),
                ]),
                this.center !== undefined && this.slot === undefined
                    ? m('.flex.flex-1.sm:justify-end.mt-4.sm:mt-0', [
                        this.renderReturnButton()
                    ])
                    : false,
            ]),
            m('.flex.flex-col', [
                this.proband === undefined
                    ? this.renderProbands()
                    : this.center === undefined
                        ? this.renderCenters()
                        : this.date === undefined
                            ? this.renderDates()
                            : this.slot === undefined
                                ? this.renderSlots()
                                : this.renderBooking()
            ]),
        ])
    }

    renderChildren() {
        const operation = this.getOperation()
        return [
                m(MainHeadline, {title: 'Neuen Testtermin buchen'}),
                operation === 'create' && this.renderForm(),
        ]
    }
}

