
export var defaultLocale = 'default'

export function getFirstDayOfWeek() {
    return 1
}

export function getShortMonthName(month, locale) {
    return new Date(1, month, 1).toLocaleString(locale || defaultLocale, { month: 'short' })
}

export function getLongMonthName(month, locale) {
    return new Date(1, month, 1).toLocaleString(locale || defaultLocale, { month: 'long' })
}

export function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate()
}

const weekdayNameBuffer = {}

export function getWeekDayNames(kind, locale) {
    const key = kind + '#' + locale
    let weekDays = weekdayNameBuffer[key]
    if (weekDays) return [...weekDays]
    const date = new Date(Date.UTC(2017, 0, 1))
    weekDays = []
    for(let i = 0; i < 7; i++) {
        weekDays.push(date.toLocaleDateString(locale || defaultLocale, { weekday: kind || 'long' }))
        date.setDate(date.getDate() + 1)
    }
    weekdayNameBuffer[key] = weekDays
    return [...weekDays]
}

export function getLongWeekDayNames(locale) {
    return getWeekDayNames('long', locale || defaultLocale)
}

export function getShortWeekDayNames(locale) {
    return getWeekDayNames('short', locale || defaultLocale)
}

export function getLongWeekDayName(date, locale) {
    return date.toLocaleDateString(locale || defaultLocale, { weekday: 'long' })
}

export function getShortWeekDayName(date, locale) {
    return date.toLocaleDateString(locale || defaultLocale, { weekday: 'short' })
}

export const toDate = (value) => value instanceof Date && new Date(value.getFullYear(), value.getMonth(), value.getDate())
export const minDate = (a, b) => a < b ? a : b
export const maxDate = (a, b) => a < b ? b : a
export const dateEqual = (a, b) => a instanceof Date && b instanceof Date && a.toDateString() === b.toDateString()

export const toDateString = (date) => date instanceof Date ?
    date.toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }) : undefined

export const toTimeString = (date, seconds) => date instanceof Date &&
    date.toLocaleTimeString(undefined, Object.assign({
        hour: '2-digit',
        minute: '2-digit'
    }, (seconds ? {second: '2-digit'} : {})))

export const toDateRangeString = (range) => {
    if (!Array.isArray(range)) return undefined
    return dateEqual(range[0], range[1]) ?
        toDateString(range[0]) :
        (
            (range[0] ? toDateString(range[0]) : '∞') + ' - ' +
            (range[1] ? toDateString(range[1]) : '∞')
        )
}